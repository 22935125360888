import React from 'react';
import '../globals.css';
import * as ReactDOM from 'react-dom/client';
import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom';
import i18n from '../lib/i18n/i18n';
import { MainLayout } from '../components/layouts/main-layout';
import { I18nextProvider } from 'react-i18next';
import { Toaster } from '../components/ui/sonner';
import { TRPCProvider } from '@/lib/providers/trpc';
import { ThemeProvider } from '../lib/providers/theme-provider';
import { CustomErrorPage } from '../pages/error-page';
import { initSentry } from '@/lib/sentry';

// Auth
import { LoginPage } from '../pages/auth/login-page';
import { VerifyPage } from '../pages/auth/verify-page';
import { CreateProfilePage } from '../pages/auth/create-profile-page';
import { AuthLayout } from '../components/layouts/auth-layout';
import { ProfilePage } from '../pages/profile/profile-page';
import { TenantsChangePage } from '../pages/auth/tenants-change-page';

// Sys Admin
import { SysAdminLayout } from '../components/layouts/sys-admin-layout';

import { SysAdminDocumentCategoriesPage } from '@/pages/app-main/sys-admin/sys-admin-document-categories/sys-admin-document-categories-page';
import { SysAdminDocumentCategoriesCreatePage } from '@/pages/app-main/sys-admin/sys-admin-document-categories/sys-admin-document-categories-create-page';
import { SysAdminDocumentCategoriesEditPage } from '@/pages/app-main/sys-admin/sys-admin-document-categories/sys-admin-document-categories-edit-page';

import { SysAdminUsersCreatePage } from '@/pages/app-main/sys-admin/sys-admin-users/sys-admin-users-create-page';

import { SysAdminCaseFormsPage } from '../pages/app-main/sys-admin/sys-admin-case-forms/sysadmin-case-forms-page';
import { SysAdminCaseFormsEditPage } from '../pages/app-main/sys-admin/sys-admin-case-forms/sysadmin-case-forms-edit-page';
import { SysAdminCaseFormsCreatePage } from '../pages/app-main/sys-admin/sys-admin-case-forms/sysadmin-case-forms-create-page';

import { SysAdminInspectionFormsPage } from '../pages/app-main/sys-admin/sys-admin-inspection-forms/sysadmin-inspection-forms-page';
import { SysAdminInspectionFormsEditPage } from '../pages/app-main/sys-admin/sys-admin-inspection-forms/sysadmin-inspection-forms-edit-page';
import { SysAdminInspectionFormsCreatePage } from '../pages/app-main/sys-admin/sys-admin-inspection-forms/sysadmin-inspection-forms-create-page';

import { SysAdminVariablesPage } from '../pages/app-main/sys-admin/sys-admin-variables/sysadmin-variables-page';
import { SysAdminVariablesEditPage } from '../pages/app-main/sys-admin/sys-admin-variables/sysadmin-variables-edit-page';
import { SysAdminVariablesCreatePage } from '../pages/app-main/sys-admin/sys-admin-variables/sysadmin-variables-create-page';

import { SysAdminTenantsPage } from '../pages/app-main/sys-admin/sys-admin-tenants/sys-admin-tenants-page';
import { SysAdminTenantsCreatePage } from '../pages/app-main/sys-admin/sys-admin-tenants/sys-admin-tenants-create-page';

import { SysAdminUsersPage } from '../pages/app-main/sys-admin/sys-admin-users/sys-admin-users-page';
import { SysAdminUsersEditPage } from '../pages/app-main/sys-admin/sys-admin-users/sys-admin-users-edit-page';

import { SysAdminNotificationsPage } from '@/pages/app-main/sys-admin/sys-admin-notifications/sys-admin-notifications-page';
import { SysAdminNotificationsEditPage } from '@/pages/app-main/sys-admin/sys-admin-notifications/sys-admin-notifications-edit-page';
import { SysAdminNotificationsCreatePage } from '@/pages/app-main/sys-admin/sys-admin-notifications/sys-admin-notifications-create-page';

// Tenant Settings
import { TenantSettingsLayout } from '../components/layouts/tenant-settings-layout';
import { TenantSettingsUsersEditPage } from '../pages/app-main/tenant-settings/tenant-settings-users/tenant-settings-users-edit-page';

import { TenantSettingsUsersPage } from '../pages/app-main/tenant-settings/tenant-settings-users/tenant-settings-users-page';
import { TenantSettingsUsersCreatePage } from '../pages/app-main/tenant-settings/tenant-settings-users/tenant-settings-users-create-page';

import { TenantSettingsDocumentCategoriesPage } from '@/pages/app-main/tenant-settings/tenant-settings-document-categories/tenant-settings-document-categories-page';
import { TenantSettingsDocumentCategoriesCreatePage } from '@/pages/app-main/tenant-settings/tenant-settings-document-categories/tenant-settings-document-categories-create-page';
import { TenantSettingsDocumentCategoriesEditPage } from '@/pages/app-main/tenant-settings/tenant-settings-document-categories/tenant-settings-document-categories-edit-page';

import { TenantSettingsGroupsPage } from '../pages/app-main/tenant-settings/tenant-settings-groups/tenant-settings-groups-page';
import { TenantSettingsGroupsCreatePage } from '../pages/app-main/tenant-settings/tenant-settings-groups/tenant-settings-groups-create-page';
import { TenantSettingsGroupsEditPage } from '../pages/app-main/tenant-settings/tenant-settings-groups/tenant-settings-groups-edit-page';

import { TenantSettingsLocationsPage } from '../pages/app-main/tenant-settings/tenant-settings-locations/tenant-settings-locations-page';
import { TenantSettingsLocationsCreatePage } from '../pages/app-main/tenant-settings/tenant-settings-locations/tenant-settings-locations-create-page';
import { TenantSettingsLocationsEditPage } from '../pages/app-main/tenant-settings/tenant-settings-locations/tenant-settings-locations-edit-page';

import { TenantSettingsDepartmentsCreatePage } from '../pages/app-main/tenant-settings/tenant-settings-departments/tenant-settings-departments-create-page';
import { TenantSettingsDepartmentsEditPage } from '../pages/app-main/tenant-settings/tenant-settings-departments/tenant-settings-departments-edit-page';
import { TenantSettingsDepartmentsPage } from '../pages/app-main/tenant-settings/tenant-settings-departments/tenant-settings-departments-page';

import { TenantSettingsProjectsCreatePage } from '../pages/app-main/tenant-settings/tenant-settings-projects/tenant-settings-projects-create-page';
import { TenantSettingsProjectsEditPage } from '../pages/app-main/tenant-settings/tenant-settings-projects/tenant-settings-projects-edit-page';
import { TenantSettingsProjectsPage } from '../pages/app-main/tenant-settings/tenant-settings-projects/tenant-settings-projects-page';

import { TenantSettingsCaseFormsPage } from '../pages/app-main/tenant-settings/tenant-settings-case-forms/tenant-case-forms-page';
import { TenantSettingsCaseFormsEditPage } from '../pages/app-main/tenant-settings/tenant-settings-case-forms/tenant-case-forms-edit-page';
import { TenantSettingsCaseFormsCreatePage } from '../pages/app-main/tenant-settings/tenant-settings-case-forms/tenant-case-forms-create-page';

import { TenantSettingsInspectionFormsPage } from '../pages/app-main/tenant-settings/tenant-settings-inspection-forms/tenant-inspection-forms-page';
import { TenantSettingsInspectionFormsEditPage } from '../pages/app-main/tenant-settings/tenant-settings-inspection-forms/tenant-inspection-forms-edit-page';
import { TenantSettingsInspectionFormsCreatePage } from '../pages/app-main/tenant-settings/tenant-settings-inspection-forms/tenant-inspection-forms-create-page';

// Supplier
import { SupplierOverviewPage } from '../pages/app-main/supplier-details/suppliers-overview-page';
import { SupplierDetailsLayout } from '../components/layouts/supplier-details-layout';
import { SupplierEditPage } from '@/pages/app-main/supplier-details/supplier-edit-page';
import { SuppliersPage } from '../pages/app-main/suppliers/suppliers-page';
import { SuppliersCreatePage } from '../pages/app-main/suppliers/suppliers-create-page';
import { SupplierCasesPage } from '../pages/app-main/supplier-details/supplier-cases-page';
import { SupplierDeviationsPage } from '@/pages/app-main/supplier-details/supplier-deviations-page';
import { SupplierInspectionsPage } from '@/pages/app-main/supplier-details/supplier-inspections-page';

// Documents
import { SupplierDocumentsPage } from '@/pages/app-main/supplier-details/supplier-documents/supplier-documents-page';
import { SupplierDocumentsCreatePage } from '@/pages/app-main/supplier-details/supplier-documents/supplier-documents-create-page';
import { SupplierDocumentsEditPage } from '@/pages/app-main/supplier-details/supplier-documents/supplier-documents-edit-page';

// Cases
import { CasesPage } from '../pages/app-main/cases/cases-page';
import { CasesCreatePage } from '../pages/app-main/cases/cases-create-page';
import { CaseDetailsPage } from '../pages/app-main/cases/case-details-page';

// Deviations
import { DeviationsPage } from '@/pages/app-main/deviations/deviations-page';
import { DeviationsCreatePage } from '@/pages/app-main/deviations/deviations-create-page';
import { DeviationsDetailsPage } from '@/pages/app-main/deviations/deviations-details-page';

// Inspections
import { InspectionsPage } from '@/pages/app-main/inspections/inspections-page';
import { InspectionsDetailsPage } from '@/pages/app-main/inspections/inspections-details-page';

// Tasks
import { TasksLayout } from '@/components/layouts/tasks-layout';
import { CaseResponseTasksPage } from '@/pages/app-main/tasks/case-response-tasks-page';
import { InspectionUnprocessedTasksPage } from '@/pages/app-main/tasks/inspection-unprocessed-tasks-page';
import { CaseNoResponseTasksPage } from '@/pages/app-main/tasks/case-noresponse-tasks-page';
import { DocumentsMissingTasksPage } from '@/pages/app-main/tasks/documents-missing-tasks-page';
import { DocumentsExpiredTasksPage } from '@/pages/app-main/tasks/documents-expired-tasks-page';
import { CaseEmailsFailedTasksPage } from '@/pages/app-main/tasks/case-emails-failed-tasks-page';

// Supplier Contacts
import { SupplierContactsPage } from '@/pages/app-main/supplier-details/supplier-contacts/supplier-contacts-page';
import { SupplierContactsCreatePage } from '@/pages/app-main/supplier-details/supplier-contacts/supplier-contacts-create-page';
import { SupplierContactsEditPage } from '@/pages/app-main/supplier-details/supplier-contacts/supplier-contacts-edit-page';

// Automatic Cases
import { TenantSettingsAutomaticCasesPage } from '@/pages/app-main/tenant-settings/tenant-settings-automatic-cases/tenant-settings-automatic-cases-page';
import { TenantSettingsAutomaticCasesEditPage } from '@/pages/app-main/tenant-settings/tenant-settings-automatic-cases/tenant-settings-automatic-cases-edit-page';
import { TenantSettingsAutomaticCasesCreatePage } from '@/pages/app-main/tenant-settings/tenant-settings-automatic-cases/tenant-settings-automatic-cases-create-page';
import { TenantEditPage } from '@/pages/app-main/tenant-settings/tenant-edit-page';

// Front page
import { FrontPage } from '@/pages/app-main/front-page';
import { SuppliersCreateMultiplePage } from '@/pages/app-main/suppliers/suppliers-create-multiple-page';

// Initialize Sentry
initSentry();

const router = createBrowserRouter([
	{
		path: '/',
		errorElement: import.meta.env.PROD && <CustomErrorPage />,
		children: [
			{
				index: true,
				loader: () => redirect('./tenants'),
			},
			{
				path: 'tenants',
				element: <MainLayout />,
				children: [
					{
						index: true,
						element: <TenantsChangePage />,
					},
					{
						path: ':tenantId',
						children: [
							{
								index: true,
								element: <FrontPage />,
							},
							{
								path: 'suppliers',
								children: [
									{
										index: true,
										element: <SuppliersPage />,
									},
									{
										path: ':supplierId',
										element: <SupplierDetailsLayout />,
										children: [
											{
												index: true,
												loader: () => redirect('./overview'),
											},
											{
												path: 'overview',
												element: <SupplierOverviewPage />,
											},
											{
												path: 'cases',
												element: <SupplierCasesPage />,
											},
											{
												path: 'inspections',
												element: <SupplierInspectionsPage />,
											},
											{
												path: 'deviations',
												children: [
													{
														index: true,
														element: <SupplierDeviationsPage />,
													},
												],
											},
											{
												path: 'edit',
												element: <SupplierEditPage />,
											},
											{
												path: 'contacts',
												children: [
													{ index: true, element: <SupplierContactsPage /> },
													{
														path: 'create',
														element: <SupplierContactsCreatePage />,
													},
													{
														path: ':id/edit',
														element: <SupplierContactsEditPage />,
													},
												],
											},
											{
												path: 'documents',
												children: [
													{ index: true, element: <SupplierDocumentsPage /> },
													{
														path: 'create',
														element: <SupplierDocumentsCreatePage />,
													},
													{
														path: ':id/edit',
														element: <SupplierDocumentsEditPage />,
													},
												],
											},
										],
									},
									{
										path: 'create',
										element: <SuppliersCreatePage />,
									},
									{
										path: 'create-multiple',
										element: <SuppliersCreateMultiplePage />,
									},
								],
							},
							{
								path: 'tasks',
								element: <TasksLayout />,
								children: [
									{
										index: true,
										loader: () => redirect('./case-response'),
									},
									{
										path: 'case-response',
										element: <CaseResponseTasksPage />,
									},
									{
										path: 'case-noresponse',
										element: <CaseNoResponseTasksPage />,
									},
									{
										path: 'inspection-unprocessed',
										element: <InspectionUnprocessedTasksPage />,
									},
									{
										path: 'documents-missing',
										element: <DocumentsMissingTasksPage />,
									},
									{
										path: 'documents-expired',
										element: <DocumentsExpiredTasksPage />,
									},
									{
										path: 'case-emails-failed',
										element: <CaseEmailsFailedTasksPage />,
									},
								],
							},
							{
								path: 'cases',
								children: [
									{
										index: true,
										element: <CasesPage />,
									},
									{
										path: 'create',
										element: <CasesCreatePage />,
									},
									{
										path: ':caseId',
										element: <CaseDetailsPage />,
									},
								],
							},

							{
								path: 'inspections',
								children: [
									{
										index: true,
										element: <InspectionsPage />,
									},
									{
										path: ':inspectionId',
										element: <InspectionsDetailsPage />,
									},
								],
							},
							{
								path: 'deviations',
								children: [
									{
										index: true,
										element: <DeviationsPage />,
									},
									{
										path: 'create',
										element: <DeviationsCreatePage />,
									},
									{
										path: ':id',
										element: <DeviationsDetailsPage />,
									},
								],
							},
							{
								path: 'settings',
								element: <TenantSettingsLayout />,
								children: [
									{
										index: true,
										loader: () => redirect('./edit'),
									},
									{
										path: 'edit',
										element: <TenantEditPage />,
									},
									{
										path: 'users',
										children: [
											{
												index: true,
												element: <TenantSettingsUsersPage />,
											},
											{
												path: 'create',
												element: <TenantSettingsUsersCreatePage />,
											},
											{
												path: ':id/edit',
												element: <TenantSettingsUsersEditPage />,
											},
										],
									},

									{
										path: 'document-categories',
										children: [
											{
												index: true,
												element: <TenantSettingsDocumentCategoriesPage />,
											},
											{
												path: 'create',
												element: <TenantSettingsDocumentCategoriesCreatePage />,
											},
											{
												path: ':id/edit',
												element: <TenantSettingsDocumentCategoriesEditPage />,
											},
										],
									},

									{
										path: 'groups',
										children: [
											{
												index: true,
												element: <TenantSettingsGroupsPage />,
											},
											{
												path: 'create',
												element: <TenantSettingsGroupsCreatePage />,
											},
											{
												path: ':id/edit',
												element: <TenantSettingsGroupsEditPage />,
											},
										],
									},
									{
										path: 'projects',
										children: [
											{
												index: true,
												element: <TenantSettingsProjectsPage />,
											},
											{
												path: 'create',
												element: <TenantSettingsProjectsCreatePage />,
											},
											{
												path: ':id/edit',
												element: <TenantSettingsProjectsEditPage />,
											},
										],
									},
									{
										path: 'departments',
										children: [
											{
												index: true,
												element: <TenantSettingsDepartmentsPage />,
											},
											{
												path: 'create',
												element: <TenantSettingsDepartmentsCreatePage />,
											},
											{
												path: ':id/edit',
												element: <TenantSettingsDepartmentsEditPage />,
											},
										],
									},
									{
										path: 'locations',
										children: [
											{
												index: true,
												element: <TenantSettingsLocationsPage />,
											},
											{
												path: 'create',
												element: <TenantSettingsLocationsCreatePage />,
											},
											{
												path: ':id/edit',
												element: <TenantSettingsLocationsEditPage />,
											},
										],
									},
									{
										path: 'case-forms',
										children: [
											{
												index: true,
												element: <TenantSettingsCaseFormsPage />,
											},
											{
												path: ':id/edit',
												element: <TenantSettingsCaseFormsEditPage />,
											},
											{
												path: 'create',
												element: <TenantSettingsCaseFormsCreatePage />,
											},
										],
									},
									{
										path: 'automatic-cases',
										children: [
											{
												index: true,
												element: <TenantSettingsAutomaticCasesPage />,
											},
											{
												path: ':id/edit',
												element: <TenantSettingsAutomaticCasesEditPage />,
											},
											{
												path: 'create',
												element: <TenantSettingsAutomaticCasesCreatePage />,
											},
										],
									},
									{
										path: 'inspection-forms',
										children: [
											{
												index: true,
												element: <TenantSettingsInspectionFormsPage />,
											},
											{
												path: ':id/edit',
												element: <TenantSettingsInspectionFormsEditPage />,
											},
											{
												path: 'create',
												element: <TenantSettingsInspectionFormsCreatePage />,
											},
										],
									},
								],
							},
						],
					},
				],
			},
			{
				path: 'user',
				element: <MainLayout />,
				children: [
					{
						index: true,
						loader: () => redirect('./profile'),
					},
					{
						path: 'profile',
						element: <ProfilePage />,
					},
				],
			},
			{
				path: 'sysadmin',
				element: <MainLayout />,
				children: [
					{
						element: <SysAdminLayout />,
						children: [
							{
								index: true,
								loader: () => redirect('./tenants'),
							},
							{
								path: 'tenants',
								children: [
									{
										index: true,
										element: <SysAdminTenantsPage />,
									},
									{
										path: 'create',
										element: <SysAdminTenantsCreatePage />,
									},
								],
							},
							{
								path: 'document-categories',
								children: [
									{
										index: true,
										element: <SysAdminDocumentCategoriesPage />,
									},
									{
										path: 'create',
										element: <SysAdminDocumentCategoriesCreatePage />,
									},
									{
										path: ':id/edit',
										element: <SysAdminDocumentCategoriesEditPage />,
									},
								],
							},

							{
								path: 'case-forms',
								children: [
									{
										index: true,
										element: <SysAdminCaseFormsPage />,
									},
									{
										path: ':id/edit',
										element: <SysAdminCaseFormsEditPage />,
									},
									{
										path: 'create',
										element: <SysAdminCaseFormsCreatePage />,
									},
								],
							},
							{
								path: 'inspection-forms',
								children: [
									{
										index: true,
										element: <SysAdminInspectionFormsPage />,
									},
									{
										path: ':id/edit',
										element: <SysAdminInspectionFormsEditPage />,
									},
									{
										path: 'create',
										element: <SysAdminInspectionFormsCreatePage />,
									},
								],
							},
							{
								path: 'system-variables',
								children: [
									{
										index: true,
										element: <SysAdminVariablesPage />,
									},
									{
										path: ':id/edit',
										element: <SysAdminVariablesEditPage />,
									},
									{
										path: 'create',
										element: <SysAdminVariablesCreatePage />,
									},
								],
							},
							{
								path: 'users',
								children: [
									{
										index: true,
										element: <SysAdminUsersPage />,
									},
									{
										path: ':id/edit',
										element: <SysAdminUsersEditPage />,
									},
									{
										path: 'create',
										element: <SysAdminUsersCreatePage />,
									},
								],
							},
							{
								path: 'notifications',
								children: [
									{
										index: true,
										element: <SysAdminNotificationsPage />,
									},
									{
										path: ':id/edit',
										element: <SysAdminNotificationsEditPage />,
									},
									{
										path: 'create',
										element: <SysAdminNotificationsCreatePage />,
									},
								],
							},
						],
					},
				],
			},
			{
				path: 'auth',
				element: <AuthLayout />,
				children: [
					{
						path: 'login',
						element: <LoginPage />,
					},
					{
						path: 'verify/:id',
						element: <VerifyPage />,
					},
					{
						path: 'create-profile',
						element: <CreateProfilePage />,
					},
				],
			},
		],
	},
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<I18nextProvider i18n={i18n} defaultNS={'translation'}>
			<ThemeProvider defaultTheme="light" storageKey="timp-ui-theme">
				<TRPCProvider>
					<RouterProvider router={router} />
					<Toaster />
				</TRPCProvider>
			</ThemeProvider>
		</I18nextProvider>
	</React.StrictMode>,
);
