import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { RouteMenuLink } from './route-menu-link';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { TypographyH5, TypographyP } from '../ui/typography';
import { PageSidebar } from './page';
import { FileClockIcon, FileSearch2Icon, FileX2Icon, MailQuestionIcon, PaperclipIcon } from 'lucide-react';
import { trpc } from '@/lib/providers/trpc';
import { cn } from '@/lib/utils';

function TaskCountStatus({ count }: { count?: number }) {
	if (count === undefined) {
		return null;
	}
	if (count === 0) {
		return null;
	}
	return count > 99 ? '(99+)' : `(${count})`;
}

export function TasksLayout() {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();

	const tasksQuery = trpc.tenantTasksCount.useQuery(
		{ tenantId: tenantId! },
		{
			refetchInterval: 1000 * 60 * 1, // 1 minute
		},
	);

	return (
		<div className="flex h-full w-full flex-1 flex-grow overflow-hidden">
			<PageSidebar>
				<div className="flex flex-col gap-y-1">
					<TypographyH5 className="line-clamp-2 text-white dark:text-primary/50">{t('tasks')}</TypographyH5>

					<TypographyP className="text-background/50 dark:text-primary/50">{t('cases')}</TypographyP>
					<div className="flex flex-col gap-y-1">
						<RouteMenuLink to={`/tenants/${tenantId}/tasks/case-response`}>
							<span
								className={cn(
									'flex items-center gap-x-1.5 text-nowrap text-background/80 dark:text-primary/60',
									tasksQuery.data?.CASES_RESPONSE == 0 && 'text-muted-foreground',
								)}
							>
								<PaperclipIcon className="size-4" />
								{t('case_response_tasks')}
							</span>
							{<TaskCountStatus count={tasksQuery.data?.CASES_RESPONSE} />}
						</RouteMenuLink>

						<RouteMenuLink to={`/tenants/${tenantId}/tasks/case-noresponse`}>
							<span
								className={cn(
									'flex items-center gap-x-1.5 text-nowrap text-background/80 dark:text-primary/60',
									tasksQuery.data?.CASES_NO_RESPONSE == 0 && 'text-muted-foreground',
								)}
							>
								<MailQuestionIcon className="size-4" />
								{t('case_noresponse_tasks')}
							</span>
							{<TaskCountStatus count={tasksQuery.data?.CASES_NO_RESPONSE} />}
						</RouteMenuLink>

						<RouteMenuLink to={`/tenants/${tenantId}/tasks/case-emails-failed`}>
							<span
								className={cn(
									'flex items-center gap-x-1.5 text-nowrap text-background/80 dark:text-primary/60',
									tasksQuery.data?.CASES_EMAILS_FAILED == 0 && 'text-muted-foreground',
								)}
							>
								<MailQuestionIcon className="size-4" />
								{t('case_failed_emails_task')}
							</span>
							{<TaskCountStatus count={tasksQuery.data?.CASES_EMAILS_FAILED} />}
						</RouteMenuLink>
					</div>

					<TypographyP className="text-background/50 dark:text-primary/50">{t('inspections')}</TypographyP>
					<div className="flex flex-col gap-y-1">
						<RouteMenuLink to={`/tenants/${tenantId}/tasks/inspection-unprocessed`}>
							<span
								className={cn(
									'flex items-center gap-x-1.5 text-nowrap text-background/80 dark:text-primary/60',
									tasksQuery.data?.INSPECTION_UNPROCESSED == 0 && 'text-muted-foreground',
								)}
							>
								<FileClockIcon className="size-4" />
								{t('inspection_unprocessed_tasks')}
							</span>
							{<TaskCountStatus count={tasksQuery.data?.INSPECTION_UNPROCESSED} />}
						</RouteMenuLink>
					</div>

					<TypographyP className="text-background/50 dark:text-primary/50">{t('documents')}</TypographyP>
					<div className="flex flex-col gap-y-1">
						<RouteMenuLink to={`/tenants/${tenantId}/tasks/documents-missing`}>
							<span
								className={cn(
									'flex items-center gap-x-1.5 text-nowrap text-background/80 dark:text-primary/60',
									tasksQuery.data?.DOCUMENTS_MISSING == 0 && 'text-muted-foreground',
								)}
							>
								<FileSearch2Icon className="size-4" />
								{t('missing_documents')}
							</span>
							{<TaskCountStatus count={tasksQuery.data?.DOCUMENTS_MISSING} />}
						</RouteMenuLink>
						<RouteMenuLink to={`/tenants/${tenantId}/tasks/documents-expired`}>
							<span
								className={cn(
									'flex items-center gap-x-1.5 text-nowrap text-background/80 dark:text-primary/60',
									tasksQuery.data?.DOCUMENTS_EXPIRED == 0 && 'text-muted-foreground',
								)}
							>
								<FileX2Icon className="size-4" />
								{t('expired_documents')}
							</span>
							{<TaskCountStatus count={tasksQuery.data?.DOCUMENTS_EXPIRED} />}
						</RouteMenuLink>
					</div>
				</div>
			</PageSidebar>

			<div className="flex flex-1 flex-col overflow-auto">
				<Outlet />
			</div>
		</div>
	);
}
