import { Button } from '@/components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { tenantsInsertInputSchema } from '@timp/server/src/schemas/tenants-insert.schema';
import { useTranslation } from 'react-i18next';
import { trpc } from '@/lib/providers/trpc';
import { useNavigate } from 'react-router-dom';
import { LinkButton } from '@/components/buttons/link-button';
import { Checkbox } from '@/components/ui/checkbox';
import { Page, PageTitle, PageToolbar, PageContent } from '@/components/layouts/page';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

export function SysAdminTenantsCreatePage() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { mutate, isLoading } = trpc.tenantsInsert.useMutation({
		onSuccess() {
			navigate(`../`);
		},
	});

	const form = useForm<z.infer<typeof tenantsInsertInputSchema>>({
		resolver: zodResolver(tenantsInsertInputSchema),
		defaultValues: {
			name: '',
			enableDepartments: false,
			enableGroups: false,
			enableProjects: false,
			enableLocations: false,
			enableAutoProcessInspections: false,
		},
	});

	return (
		<Page size="container">
			<PageToolbar>
				<PageTitle backLink>{t('create')}</PageTitle>
			</PageToolbar>
			<PageContent scroll>
				<Form {...form}>
					<form onSubmit={form.handleSubmit((data) => mutate(data))} className="space-y-8">
						<FormField
							control={form.control}
							name="name"
							render={({ field }) => (
								<FormItem>
									<FormLabel required>{t('name')}</FormLabel>
									<FormControl>
										<Input placeholder="Transport og Logistikk AS" {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="language"
							render={({ field }) => (
								<FormItem>
									<FormLabel required>{t('language')}</FormLabel>
									<Select onValueChange={field.onChange} value={field.value}>
										<FormControl>
											<SelectTrigger>
												<SelectValue />
											</SelectTrigger>
										</FormControl>
										<SelectContent>
											<SelectItem value="en">{t('language_english')}</SelectItem>
											<SelectItem value="no">{t('language_norwegian')}</SelectItem>
										</SelectContent>
									</Select>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="enableDepartments"
							render={({ field }) => (
								<FormItem className="flex items-center gap-x-2 space-y-0">
									<FormControl>
										<Checkbox onCheckedChange={field.onChange} name={field.name} checked={field.value} />
									</FormControl>
									<FormMessage />
									<FormLabel required={false}>{t('enable_departments')}</FormLabel>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="enableGroups"
							render={({ field }) => (
								<FormItem className="flex items-center gap-x-2 space-y-0">
									<FormControl>
										<Checkbox onCheckedChange={field.onChange} name={field.name} checked={field.value} />
									</FormControl>
									<FormLabel required={false}>{t('enable_groups')}</FormLabel>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="enableProjects"
							render={({ field }) => (
								<FormItem className="flex items-center gap-x-2 space-y-0">
									<FormControl>
										<Checkbox onCheckedChange={field.onChange} name={field.name} checked={field.value} />
									</FormControl>
									<FormLabel required={false}>{t('enable_projects')}</FormLabel>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="enableLocations"
							render={({ field }) => (
								<FormItem className="flex items-center gap-x-2 space-y-0">
									<FormControl>
										<Checkbox onCheckedChange={field.onChange} name={field.name} checked={field.value} />
									</FormControl>
									<FormLabel required={false}>{t('enable_locations')}</FormLabel>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="enableAutoProcessInspections"
							render={({ field }) => (
								<FormItem className="flex flex-col gap-y-1 space-y-0">
									<div className="flex items-center gap-x-2 space-y-0">
										<FormControl>
											<Checkbox onCheckedChange={field.onChange} name={field.name} checked={field.value} />
										</FormControl>
										<FormLabel required={false}>{t('enable_auto_process_inspections')}</FormLabel>
									</div>
									<FormDescription>{t('enable_auto_process_inspections_description')}</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<div className="flex justify-end gap-4">
							<LinkButton variant="outline" to="../">
								{t('cancel')}
							</LinkButton>

							<Button isLoading={isLoading} type="submit">
								{t('tenant_create')}
							</Button>
						</div>
					</form>
				</Form>
			</PageContent>
		</Page>
	);
}
