import { UnsavedChangesBlocker } from '@/components/dialogs/unsaved-changes-blocker';
import { FormBuilder } from '@/components/form-builders/form-builder';
import { Button } from '@/components/ui/button';
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';
import { AlertDialog, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '@/components/ui/alert-dialog';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Switch } from '@/components/ui/switch';
import { trpc } from '@/lib/providers/trpc';
import { zodResolver } from '@hookform/resolvers/zod';
import { formTemplatesCommonInsertFormTemplateSchemaInput } from '@timp/server/src/schemas/form-templates-common.schema';
import { EditIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export function SysAdminInspectionFormsEditPage() {
	const { t } = useTranslation();
	const { id } = useParams();

	const { data } = trpc.sysadminFormTemplatesById.useQuery({
		id: id!,
	});

	const updateMutation = trpc.sysadminFormTemplatesUpdateById.useMutation({
		onSuccess() {
			form.reset(form.getValues());
		},
	});

	const form = useForm({
		resolver: zodResolver(formTemplatesCommonInsertFormTemplateSchemaInput),
		values: data,
	});

	useEffect(() => {
		form.reset(data);
	}, [data, form]);

	const formName = useController({
		control: form.control,
		name: 'name',
	});

	const [dialogOpen, setDialogOpen] = useState(false);

	const hasUnsavedChanges = !!Object.entries(form.formState.dirtyFields).length;
	return (
		<Page>
			<Form {...form}>
				<PageToolbar>
					<PageTitle backLink>
						<div className="flex flex-row items-center gap-x-1">
							{formName.field.value}
							<Button
								className="ml-1 h-5 w-5"
								onClick={() => {
									setDialogOpen(true);
								}}
								variant="ghost"
								size="icon"
							>
								<EditIcon />
							</Button>
						</div>
					</PageTitle>

					<PageActions>
						<FormField
							control={form.control}
							name="published"
							render={({ field }) => (
								<FormItem className="flex items-center gap-x-2 space-y-0">
									<FormLabel required={false}>{t('published')}</FormLabel>
									<FormControl>
										<Switch checked={field.value} onCheckedChange={field.onChange} name={field.name} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>

						<Button
							isLoading={updateMutation.isLoading}
							disabled={!hasUnsavedChanges}
							onClick={form.handleSubmit((form) => {
								updateMutation.mutate({ ...form, id: id ?? form.id });
							})}
						>
							{t('save_changes')}
						</Button>
					</PageActions>
				</PageToolbar>

				<PageContent>
					<FormBuilder />
				</PageContent>

				<AlertDialog onOpenChange={setDialogOpen} open={dialogOpen}>
					<AlertDialogContent className="sm:max-w-[425px]">
						<AlertDialogHeader>
							<AlertDialogTitle>{t('form_name')}</AlertDialogTitle>
						</AlertDialogHeader>
						<FormField
							control={form.control}
							name="name"
							render={({ field }) => (
								<FormItem>
									<FormLabel required={true}>{t('form_name')}</FormLabel>
									<FormControl>
										<Input {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<AlertDialogFooter>
							<Button
								disabled={formName.fieldState.invalid}
								onClick={() => {
									setDialogOpen(false);
								}}
							>
								{t('save_and_close')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialog>

				<UnsavedChangesBlocker hasUnsavedChanges={hasUnsavedChanges && updateMutation.isIdle} />
			</Form>
		</Page>
	);
}
