import * as z from 'zod';

export const tenantsInsertInputSchema = z.object({
	name: z.string().min(1).max(250),
	language: z.enum(['en', 'no']),
	enableDepartments: z.boolean(),
	enableGroups: z.boolean(),
	enableProjects: z.boolean(),
	enableLocations: z.boolean(),
	enableAutoProcessInspections: z.boolean(),
	isDemo: z.boolean().optional().default(false),
});

export const tenantsInsertOutputSchema = z.object({
	id: z.string().uuid(),
});
