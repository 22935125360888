import { cn } from '@/lib/utils';
import { PropsWithChildren } from 'react';
import { NavLink } from '@/components/links/Link';

export function RouteMenuLink({ to, children, className }: PropsWithChildren<{ to: string; className?: string }>) {
	return (
		<NavLink
			to={to}
			className={({ isActive }: { isActive: boolean }) => {
				return cn(
					'flex w-full items-center gap-x-1.5 rounded-md p-2 text-sm text-background/80 transition-opacity hover:bg-zinc-800 dark:text-primary/80',
					className,
					isActive && 'bg-zinc-800',
				);
			}}
		>
			{children}
		</NavLink>
	);
}
