import * as z from 'zod';

export const tenantsUpdateByIdInputSchema = z.object({
	id: z.string().uuid(),
	name: z.string().min(1).max(250).optional(),
	language: z.enum(['en', 'no']).optional(),
	enableDepartments: z.boolean().optional(),
	enableGroups: z.boolean().optional(),
	enableProjects: z.boolean().optional(),
	enableLocations: z.boolean().optional(),
	enableAutoProcessInspections: z.boolean().optional(),
});

export const tenantsUpdateByIdOutputSchema = z.object({
	id: z.string().uuid(),
});
