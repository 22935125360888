import { Button } from '@/components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { tenantsUpdateByIdInputSchema } from '@timp/server/src/schemas/tenants-update-by-id.schema';
import { useTranslation } from 'react-i18next';
import { trpc } from '@/lib/providers/trpc';
import { Checkbox } from '@/components/ui/checkbox';
import { Page, PageTitle, PageToolbar, PageContent } from '@/components/layouts/page';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { toast } from 'sonner';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

export function TenantEditPage() {
	const tenantId = useTenantIdSafe();
	const { t } = useTranslation();

	const { mutate: mutateTenant, isLoading: tenantIsLoading } = trpc.tenantsUpdateById.useMutation({
		onSuccess() {
			toast.success(t('tenant_update_success'));
		},
	});

	const { data, isLoading, error } = trpc.tenantsById.useQuery({ tenantId: tenantId! });

	const form = useForm<z.infer<typeof tenantsUpdateByIdInputSchema>>({
		resolver: zodResolver(tenantsUpdateByIdInputSchema),
		values: data,
	});

	return (
		<Page size="container" isLoading={isLoading} error={error}>
			<PageToolbar>
				<PageTitle>{t('company')}</PageTitle>
			</PageToolbar>
			<PageContent scroll>
				<Form {...form}>
					<form onSubmit={form.handleSubmit((data) => mutateTenant({ ...data, tenantId }))} className="space-y-8">
						<FormField
							control={form.control}
							name="name"
							render={({ field }) => (
								<FormItem>
									<FormLabel required>{t('name')}</FormLabel>
									<FormControl>
										<Input placeholder="Transport og Logistikk AS" {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="language"
							render={({ field }) => (
								<FormItem>
									<FormLabel required>{t('language')}</FormLabel>
									<Select onValueChange={field.onChange} value={field.value}>
										<FormControl>
											<SelectTrigger>
												<SelectValue />
											</SelectTrigger>
										</FormControl>
										<SelectContent>
											<SelectItem value="en">{t('language_english')}</SelectItem>
											<SelectItem value="no">{t('language_norwegian')}</SelectItem>
										</SelectContent>
									</Select>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="enableDepartments"
							render={({ field }) => (
								<FormItem className="flex items-center gap-x-2 space-y-0">
									<FormControl>
										<Checkbox onCheckedChange={field.onChange} name={field.name} checked={field.value} />
									</FormControl>
									<FormMessage />
									<FormLabel required={false}>{t('enable_departments')}</FormLabel>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="enableGroups"
							render={({ field }) => (
								<FormItem className="flex items-center gap-x-2 space-y-0">
									<FormControl>
										<Checkbox onCheckedChange={field.onChange} name={field.name} checked={field.value} />
									</FormControl>
									<FormLabel required={false}>{t('enable_groups')}</FormLabel>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="enableProjects"
							render={({ field }) => (
								<FormItem className="flex items-center gap-x-2 space-y-0">
									<FormControl>
										<Checkbox onCheckedChange={field.onChange} name={field.name} checked={field.value} />
									</FormControl>
									<FormLabel required={false}>{t('enable_projects')}</FormLabel>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="enableLocations"
							render={({ field }) => (
								<FormItem className="flex items-center gap-x-2 space-y-0">
									<FormControl>
										<Checkbox onCheckedChange={field.onChange} name={field.name} checked={field.value} />
									</FormControl>
									<FormLabel required={false}>{t('enable_locations')}</FormLabel>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="enableAutoProcessInspections"
							render={({ field }) => (
								<FormItem className="flex flex-col gap-y-1 space-y-0">
									<div className="flex items-center gap-x-2 space-y-0">
										<FormControl>
											<Checkbox onCheckedChange={field.onChange} name={field.name} checked={field.value} />
										</FormControl>
										<FormLabel required={false}>{t('enable_auto_process_inspections')}</FormLabel>
									</div>
									<FormDescription>{t('enable_auto_process_inspections_description')}</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<div className="flex justify-end gap-4">
							<Button isLoading={tenantIsLoading} type="submit">
								{t('save_changes')}
							</Button>
						</div>
					</form>
				</Form>
			</PageContent>
		</Page>
	);
}
