import { KeyboardEvent, memo, useState } from 'react';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { FieldError, UseFieldArrayReturn, UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { tenantSuppliersInsertManyInputSchema } from '@timp/server/src/schemas/tenant-suppliers-insert-many.schema';
import { useTranslation } from 'react-i18next';
import { trpc } from '@/lib/providers/trpc';
import { useNavigate } from 'react-router-dom';
import { useTenantFeatureFlags, useTenantIdSafe } from '@/hooks/useTenant';
import { LinkButton } from '@/components/buttons/link-button';
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { SelectMultipleProjects, SelectMultipleDepartments, SelectMultipleGroups } from '@/components/selects/select-multiple';
import { useBrregService } from '@/hooks/useBrregService';
import { AutoComplete } from '@/components/ui/autocomplete';
import { SelectRiskEvaluation } from '@/components/selects/select-risk-evaluation';
import { DownloadIcon, FileCheckIcon, PlusCircleIcon, Trash2Icon, TriangleAlertIcon } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { FileUploadInput } from '@/components/inputs/file-upload-input';
import { TypographyError, TypographyMuted, TypographySmall } from '@/components/ui/typography';
import { CellHyperlinkValue, CellRichTextValue, CellValue } from 'exceljs';
import { toast } from 'sonner';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { Badge } from '@/components/ui/badge';

type InsertManySchema = z.infer<typeof tenantSuppliersInsertManyInputSchema>;
type SupplierShape = {
	name: string;
	organizationNumber: string;
	internalId?: string;
	status: string;
	contactFullName: string;
	contactEmail: string;
	contactPhone?: string;
	contactRole?: string;
	projects?: string;
	groups?: string;
	departments?: string;
};

type SupplierInsertEntry = InsertManySchema['suppliers'][0];
function getDefaultSupplierEntry(): Partial<SupplierInsertEntry> {
	return {
		groups: [],
		departments: [],
		projects: [],
		status: 'OK',
	};
}

// Maps the header cell names from the excel files to the tenantSuppliersInsertMany object shape
const IMPORT_EXCEL_MAPPING = new Map<string, keyof SupplierShape>([
	// Norwegian mappings
	['Leverandørens navn*', 'name'],
	['Organisasjonsnummer*', 'organizationNumber'],
	['Status*', 'status'],
	['Intern ID', 'internalId'],
	['Kontaktpersonens navn*', 'contactFullName'],
	['Kontaktpersonens e-post*', 'contactEmail'],
	['Kontaktpersonens telefon', 'contactPhone'],
	['Kontaktpersonens rolle', 'contactRole'],
	['Avdelinger - adskilt av ;', 'departments'],
	['Grupper - adskilt av ;', 'groups'],
	['Prosjekter - adskilt av ;', 'projects'],

	// English mappings
	['Supplier Name*', 'name'],
	['Organization Number*', 'organizationNumber'],
	['Status*', 'status'],
	['Internal ID', 'internalId'],
	['Contact Name*', 'contactFullName'],
	['Contact Email*', 'contactEmail'],
	['Contact Phone', 'contactPhone'],
	['Contact Role', 'contactRole'],
	['Departments - separated by ;', 'departments'],
	['Groups - separated by ;', 'groups'],
	['Projects - separated by ;', 'projects'],
]);

export function SuppliersCreateMultiplePage() {
	const { t, i18n } = useTranslation();
	const tenantId = useTenantIdSafe();
	const { enableGroups, enableProjects, enableDepartments } = useTenantFeatureFlags();
	const departments = trpc.tenantDepartmentsList.useQuery({ tenantId });
	const groups = trpc.tenantGroupsList.useQuery({ tenantId });
	const projects = trpc.tenantProjectsList.useQuery({ tenantId });

	const navigate = useNavigate();

	const { mutate, isLoading } = trpc.tenantSuppliersInsertMany.useMutation({
		onSuccess() {
			navigate(`../`);
		},
	});

	const form = useForm<InsertManySchema>({
		resolver: zodResolver(tenantSuppliersInsertManyInputSchema),
		defaultValues: {
			suppliers: [getDefaultSupplierEntry()],
		},
	});

	const { append, fields, remove, replace } = useFieldArray({
		control: form.control,
		name: 'suppliers',
	});
	function insertSupplier() {
		const newSupplier = getDefaultSupplierEntry();
		if (fields.length > 0) {
			const supplierToCopy = form.getValues(`suppliers.${fields.length - 1}`);
			if (supplierToCopy) {
				newSupplier.groups = supplierToCopy.groups;
				newSupplier.departments = supplierToCopy.departments;
				newSupplier.projects = supplierToCopy.projects;
				newSupplier.status = supplierToCopy.status;
			}
		}

		append(newSupplier as NonNullable<SupplierInsertEntry>);
	}

	const [openImportDialog, setOpenImportDialog] = useState(false);

	const insertManyMutation = trpc.tenantSuppliersInsertMany.useMutation({
		onSuccess(data) {
			if (data.length > 0) {
				toast.success(t('import_suppliers_success', { successful: data.length }));
			}
			closeImportDialog();
		},
	});

	const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

	// Map over the names and ensure the name has at least 1 character (prevents empty names from being created)
	function parseExcelArray(array: string | undefined) {
		return Array.from(
			new Set(
				array
					?.split(';')
					?.filter((v) => v.length > 0)
					.map((v) => v.trim()),
			),
		);
	}

	const [isImporting, setIsImporting] = useState(false);
	async function importFromExcel() {
		try {
			setIsImporting(true);

			// Save the current form state
			const currentFormState = form.getValues();

			const exceljs = await import('exceljs');
			const file = uploadedFiles[0];
			const fileArrayBuf = await file.arrayBuffer();

			const workbook = new exceljs.Workbook();
			await workbook.xlsx.load(fileArrayBuf);

			const worksheet = workbook.getWorksheet(1)!;

			const importedSuppliers: SupplierShape[] = [];

			const headers: string[] = [];

			// Parse the header cell names
			const rawHeaders = worksheet.getRow(1).values as unknown as CellValue[];
			rawHeaders.forEach((header) => {
				console.error(header);
				let headerName = '';
				if (typeof header === 'string') {
					headerName = header;
				} else if (header && typeof header === 'object' && 'richText' in header) {
					// Concatenate the values, so we only keep the text
					headerName = (header as CellRichTextValue).richText.map((v) => v.text).join('');
				}
				const headerKey = IMPORT_EXCEL_MAPPING.get(headerName);
				if (!headerKey) {
					throw new Error(t('failed_to_parse_excel_headers'));
				}
				headers.push(headerKey);
			});

			// Iterate over each row in the worksheet, excluding the header row
			for (let i = 2; i <= worksheet.rowCount; i++) {
				const row = worksheet.getRow(i);
				const rowData: Partial<SupplierShape> = {};

				// Iterate over each cell in the row
				row.eachCell({ includeEmpty: false }, (cell, colNumber) => {
					// Parse the header cell name and map it to the correct key in the rowData object
					const headerKey = headers[colNumber - 1] as keyof SupplierShape;

					// Parse the cell value
					let value: string | undefined = '';
					if (typeof cell.value === 'string') {
						value = cell.value;
					} else if (typeof cell.value === 'number') {
						value = cell.value.toString();
					} else if (cell.value && typeof cell.value === 'object' && 'richText' in cell.value) {
						value = (cell.value as CellRichTextValue).richText.map((v) => v.text).join('');
					} else if (cell.value && typeof cell.value === 'object' && 'hyperlink' in cell.value) {
						if (typeof cell.value.text === 'object') {
							// handles file saved from numbers on mac
							value = (cell.value.text as CellRichTextValue)?.richText?.map?.((v) => v.text)?.join?.('');
						} else {
							value = (cell.value as CellHyperlinkValue)?.text;
						}
					} else if (cell.value instanceof Date) {
						value = cell.value.toISOString();
					} else {
						value = cell.value?.toString?.() ?? '';
					}

					rowData[headerKey] = value?.trim?.() || undefined;
				});

				// Special case for status, always uppercase it if it exists, so we can allow users to use lowercase 'ok' instead of 'OK'
				if (rowData.status) {
					rowData.status = rowData.status.toUpperCase();
				}

				// Skip rows that are empty
				const hasAnyNonEmptyValue = Object.values(rowData).some((v) => v !== undefined && v !== '' && v !== null && v !== ' ');
				if (!hasAnyNonEmptyValue) {
					continue;
				}
				// Make sure the row has any value in it
				if (Object.keys(rowData).length > 0) {
					importedSuppliers.push(rowData as SupplierShape);
				}
			}

			const parsedSuppliers = importedSuppliers.map((supplier) => {
				const departmentNames = parseExcelArray(supplier.departments);
				const groupNames = parseExcelArray(supplier.groups);
				const projectNames = parseExcelArray(supplier.projects);
				return {
					...supplier,
					departments: departmentNames.map((name) => {
						const department = departments.data?.find((dep) => dep.name === name);
						return { id: department?.id ?? uuidv4(), name };
					}),
					groups: groupNames.map((name) => {
						const group = groups.data?.find((grp) => grp.name === name);
						return { id: group?.id ?? uuidv4(), name };
					}),
					projects: projectNames.map((name) => {
						const project = projects.data?.find((proj) => proj.name === name);
						return { id: project?.id ?? uuidv4(), name };
					}),
				};
			});
			// remove existing suppliers that are empty from fields array
			const currentSuppliers = currentFormState.suppliers.filter((supplier) => supplier.name || supplier.organizationNumber);
			const combinedSuppliers = [...currentSuppliers, ...parsedSuppliers];
			replace(combinedSuppliers as NonNullable<SupplierInsertEntry>[]);

			closeImportDialog();
		} catch (error) {
			if (error instanceof Error) {
				toast.error(error.message);
			}
		} finally {
			setIsImporting(false);
		}
	}
	const exampleFileName = i18n.language === 'no' ? 'leverandører-eksempel.xlsx' : 'suppliers-example.xlsx';

	function closeImportDialog() {
		setUploadedFiles([]);
		setOpenImportDialog(false);
	}

	return (
		<Page size="full">
			<PageToolbar>
				<PageTitle backLink>{t('supplier_create_multiple')}</PageTitle>
				<PageActions>
					<Button type="button" variant="outline" onClick={() => setOpenImportDialog(true)}>
						{t('import_from_excel')}
					</Button>
				</PageActions>
			</PageToolbar>
			<PageContent className="flex-1">
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit((data) => mutate({ tenantId, suppliers: data.suppliers }))}
						className="flex flex-1 flex-col gap-y-8 overflow-x-hidden"
					>
						<div className="w-full flex-1 overflow-auto">
							<Table className="w-full">
								<TableHeader className="[&_tr]:border-b-0">
									<TableRow>
										<TableHead className="select-none p-2">#</TableHead>
										<TableHead className="w-52 text-xs">
											{t('name')}
											<span className="select-none pl-1 font-semibold text-destructive">*</span>
										</TableHead>
										<TableHead className="w-28 text-xs">
											{t('organizationNumber')}
											<span className="select-none pl-1 font-semibold text-destructive">*</span>
										</TableHead>
										<TableHead className="w-28 text-xs">{t('internalId')}</TableHead>
										<TableHead className="text-xs">
											{t('status')}
											<span className="select-none pl-1 font-semibold text-destructive">*</span>
										</TableHead>
										{enableGroups && <TableHead className="w-32 text-xs">{t('groups')}</TableHead>}
										{enableProjects && <TableHead className="w-32 text-xs">{t('projects')}</TableHead>}
										{enableDepartments && <TableHead className="w-32 text-xs">{t('departments')}</TableHead>}

										<TableHead className="w-32 text-xs">
											{t('contactName')}
											<span className="select-none pl-1 font-semibold text-destructive">*</span>
										</TableHead>
										<TableHead className="w-32 text-xs">
											{t('contactEmail')}
											<span className="select-none pl-1 font-semibold text-destructive">*</span>
										</TableHead>
										<TableHead className="w-32 text-xs">{t('contactPhone')}</TableHead>
										<TableHead className="w-32 text-xs">{t('contactRole')}</TableHead>
									</TableRow>
								</TableHeader>
								<TableBody key={`${isImporting}`}>
									{fields.map((item, sectionIdx) => {
										return <RenderFormSupplierSection key={item.id} sectionIdx={sectionIdx} form={form} remove={remove} append={append} fields={fields} />;
									})}

									<TableRow>
										<TableCell colSpan={14} className="w-full p-0.5">
											<Button
												type="button"
												variant="ghost"
												className="w-full"
												size="sm"
												onClick={(e) => {
													e.preventDefault();
													insertSupplier();
												}}
											>
												<PlusCircleIcon className="size-5" strokeWidth={1.5} />
												{t('insert')}
											</Button>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</div>

						<div className="flex justify-between">
							<TooltipProvider>
								<Tooltip>
									<TooltipTrigger
										type="button"
										asChild
										onClick={(event) => {
											event.preventDefault(); // prevent tooltip from closing on click
										}}
									>
										{form.formState?.errors?.suppliers?.length ? (
											<Button variant="outlineDestructive">
												<TriangleAlertIcon className="size-5 text-destructive" strokeWidth={1.5} />
												{t('errors_found')}
											</Button>
										) : (
											<div></div> // empty div to keep the button position
										)}
									</TooltipTrigger>
									<TooltipContent
										className="ml-6 max-h-80 overflow-auto border bg-white px-2 shadow-sm"
										onPointerDownOutside={(event) => {
											event.preventDefault(); // prevent tooltip from closing on click
										}}
									>
										{form.formState?.errors?.suppliers?.length && (
											<div className="flex min-w-52 max-w-xs flex-col overflow-y-auto">
												{form.formState?.errors?.suppliers?.map?.((rowErrors, rowIdx) => (
													<div key={rowIdx} className="mb-2">
														<TypographyMuted className="text-xs font-bold uppercase">{t('row_error_index', { index: rowIdx + 1 })}:</TypographyMuted>
														{Object.entries(rowErrors ?? {}).map(([key, cellError], cellIdx) => (
															<TypographyError key={key + cellIdx} className="ml-2 text-xs">
																{t(key as keyof SupplierShape)}: {(cellError as FieldError)?.message}
															</TypographyError>
														))}
													</div>
												))}
											</div>
										)}
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>

							<div className="flex justify-end gap-4">
								<LinkButton variant="outline" to="../">
									{t('cancel')}
								</LinkButton>

								<Button isLoading={isLoading} type="button" onClick={form.handleSubmit((data) => mutate({ tenantId, suppliers: data.suppliers }))}>
									{t('create_suppliers')}
								</Button>
							</div>
						</div>
					</form>
				</Form>
			</PageContent>
			<Dialog open={openImportDialog} onOpenChange={closeImportDialog}>
				<DialogContent className="sm:max-w-[625px]">
					<DialogHeader>
						<DialogTitle className="mb-4">{t('import_from_excel')}</DialogTitle>
						<DialogDescription>{t('import_suppliers_description')}</DialogDescription>
					</DialogHeader>
					<div className="flex flex-col items-center">
						<Button
							variant="link"
							className="w-fit"
							onClick={() => {
								const downloadLink = document.createElement('a');
								downloadLink.href = `/${exampleFileName}`;
								downloadLink.download = exampleFileName;
								downloadLink.click();
							}}
						>
							{exampleFileName}
							<DownloadIcon className="size-4" />
						</Button>
						<div className="flex w-full flex-col">
							<div className="w-full">
								<FileUploadInput onFilesSelected={setUploadedFiles} multiple={false} accept=".xlsx"></FileUploadInput>
							</div>
							{uploadedFiles[0] && (
								<div className="mt-4 flex items-center gap-x-1">
									<FileCheckIcon className="size-4" />
									<TypographySmall>{uploadedFiles[0].name}</TypographySmall>
								</div>
							)}
						</div>
					</div>

					<DialogFooter>
						<Button type="button" variant="ghost" onClick={closeImportDialog}>
							{t('cancel')}
						</Button>
						<Button
							isLoading={isImporting || insertManyMutation.isLoading}
							disabled={uploadedFiles.length === 0}
							type="submit"
							onClick={importFromExcel}
							data-testid="execute-import-suppliers-button"
						>
							{t('import_from_excel')}
						</Button>
					</DialogFooter>
				</DialogContent>
			</Dialog>
		</Page>
	);
}

interface RenderFormSupplierSectionProps {
	sectionIdx: number;
	form: UseFormReturn<InsertManySchema>;
	append: UseFieldArrayReturn<InsertManySchema>['append'];
	fields: UseFieldArrayReturn<InsertManySchema>['fields'];
	remove: UseFieldArrayReturn<InsertManySchema>['remove'];
}
const RenderFormSupplierSection = memo(({ form, sectionIdx, fields, append, remove }: RenderFormSupplierSectionProps) => {
	const { t } = useTranslation();
	const [searchNameValue, setSearchNameValue] = useState<string>('');
	const searchNameQuery = useBrregService(searchNameValue, { keepPreviousData: true });

	const { enableGroups, enableProjects, enableDepartments } = useTenantFeatureFlags();

	function copySupplierFromIdx(currentSupplierIdx: number) {
		const supplierToCopy = form.getValues(`suppliers.${currentSupplierIdx}`);
		const newSupplier = getDefaultSupplierEntry();

		// copy some values from the current supplier
		newSupplier.groups = supplierToCopy.groups;
		newSupplier.departments = supplierToCopy.departments;
		newSupplier.projects = supplierToCopy.projects;
		newSupplier.status = supplierToCopy.status;

		append(newSupplier as NonNullable<SupplierInsertEntry>, { shouldFocus: true });
	}

	function onKeyDown(event: KeyboardEvent<HTMLInputElement>) {
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
			// enter, go to next section
			if (!event.shiftKey) {
				const hasNextSupplier = sectionIdx < fields.length - 1;
				// create supplier if there is no next supplier
				if (!hasNextSupplier) {
					copySupplierFromIdx(sectionIdx);
					return;
				}
				form.setFocus(`suppliers.${sectionIdx + 1}.name`);
			}
			// shift + enter, go to previous section
			if (event.shiftKey) {
				form.setFocus(`suppliers.${Math.max(sectionIdx - 1, 0)}.name`);
			}
		}
	}

	return (
		<TableRow className="border-transparent">
			<TableCell className="select-none p-0.5">
				<Badge variant="secondary" className="px-2">
					{sectionIdx + 1}
				</Badge>
			</TableCell>
			<TableCell className="p-0">
				<FormField
					control={form.control}
					name={`suppliers.${sectionIdx}.name`}
					render={({ field, fieldState }) => (
						<FormItem>
							<FormControl>
								<AutoComplete
									ref={field.ref}
									name={field.name}
									autoComplete="off"
									isLoading={searchNameQuery.isInitialLoading}
									isError={searchNameQuery.isError}
									isFetching={searchNameQuery.isFetching}
									emptyMessage={t('no_results')}
									className={cn(fieldState.error && 'border-destructive focus-visible:border-destructive')}
									options={searchNameQuery.data?.map((company) => ({ label: company.name, value: company.organizationNumber })) ?? []}
									value={{
										label: field.value,
										value: field.value,
									}}
									onSearch={(val) => {
										setSearchNameValue(val);
										field.onChange(val);
										form.setValue(`suppliers.${sectionIdx}.organizationNumber`, '');
									}}
									searchValue={searchNameValue}
									onValueChange={(opt) => {
										field.onChange(opt.label || '');
										form.setValue(`suppliers.${sectionIdx}.organizationNumber`, opt.value);

										// skip to internalId if organizationNumber is filled
										if (opt.value) {
											form.setFocus(`suppliers.${sectionIdx}.internalId`);
										}
									}}
								/>
							</FormControl>
						</FormItem>
					)}
				/>
			</TableCell>
			<TableCell className="p-0.5">
				<FormField
					control={form.control}
					name={`suppliers.${sectionIdx}.organizationNumber`}
					render={({ field, fieldState }) => (
						<FormItem className="">
							<FormControl>
								<Input
									{...field}
									autoComplete="off"
									className={cn(fieldState.error && 'border-destructive focus-visible:border-destructive')}
									onKeyDown={onKeyDown}
								/>
							</FormControl>
						</FormItem>
					)}
				/>
			</TableCell>
			<TableCell className="p-0.5">
				<FormField
					control={form.control}
					name={`suppliers.${sectionIdx}.internalId`}
					render={({ field, fieldState }) => (
						<FormItem className="">
							<FormControl>
								<Input
									{...field}
									autoComplete="off"
									className={cn(fieldState.error && 'border-destructive focus-visible:border-destructive')}
									onKeyDown={onKeyDown}
								/>
							</FormControl>
						</FormItem>
					)}
				/>
			</TableCell>

			<TableCell className="p-0.5">
				<FormField
					control={form.control}
					name={`suppliers.${sectionIdx}.status`}
					render={({ field }) => (
						<FormItem className="">
							<SelectRiskEvaluation onChange={field.onChange} value={field.value} />
						</FormItem>
					)}
				/>
			</TableCell>

			{enableGroups && (
				<TableCell className="p-0.5">
					<FormField
						control={form.control}
						name={`suppliers.${sectionIdx}.groups`}
						render={({ field }) => (
							<FormItem className="">
								<SelectMultipleGroups values={field.value ?? []} onChange={field.onChange} />
							</FormItem>
						)}
					/>
				</TableCell>
			)}
			{enableProjects && (
				<TableCell className="p-0.5">
					<FormField
						control={form.control}
						name={`suppliers.${sectionIdx}.projects`}
						render={({ field }) => (
							<FormItem className="">
								<SelectMultipleProjects values={field.value ?? []} onChange={field.onChange} />
							</FormItem>
						)}
					/>
				</TableCell>
			)}
			{enableDepartments && (
				<TableCell className="p-0.5">
					<FormField
						control={form.control}
						name={`suppliers.${sectionIdx}.departments`}
						render={({ field }) => (
							<FormItem className="">
								<SelectMultipleDepartments values={field.value ?? []} onChange={field.onChange} />
							</FormItem>
						)}
					/>
				</TableCell>
			)}
			{/* Supplier contact */}
			<TableCell className="p-0.5">
				<FormField
					control={form.control}
					name={`suppliers.${sectionIdx}.contactFullName`}
					render={({ field, fieldState }) => (
						<FormItem className="">
							<FormControl>
								<Input
									{...field}
									autoComplete="off"
									className={cn(fieldState.error && 'border-destructive focus-visible:border-destructive')}
									onKeyDown={onKeyDown}
								/>
							</FormControl>
						</FormItem>
					)}
				/>
			</TableCell>
			<TableCell className="p-0.5">
				<FormField
					control={form.control}
					name={`suppliers.${sectionIdx}.contactEmail`}
					render={({ field, fieldState }) => (
						<FormItem className="">
							<FormControl>
								<Input
									{...field}
									autoComplete="off"
									type="email"
									className={cn(fieldState.error && 'border-destructive focus-visible:border-destructive')}
									onKeyDown={onKeyDown}
								/>
							</FormControl>
						</FormItem>
					)}
				/>
			</TableCell>
			<TableCell className="p-0.5">
				<FormField
					control={form.control}
					name={`suppliers.${sectionIdx}.contactPhone`}
					render={({ field, fieldState }) => (
						<FormItem className="">
							<FormControl>
								<Input
									{...field}
									value={field.value ?? ''}
									autoComplete="off"
									className={cn(fieldState.error && 'border-destructive focus-visible:border-destructive')}
									onKeyDown={onKeyDown}
								/>
							</FormControl>
						</FormItem>
					)}
				/>
			</TableCell>
			<TableCell className="p-0.5">
				<FormField
					control={form.control}
					name={`suppliers.${sectionIdx}.contactRole`}
					render={({ field, fieldState }) => (
						<FormItem className="">
							<FormControl>
								<Input
									{...field}
									value={field.value ?? ''}
									autoComplete="off"
									className={cn(fieldState.error && 'border-destructive focus-visible:border-destructive')}
									onKeyDown={onKeyDown}
								/>
							</FormControl>
						</FormItem>
					)}
				/>
			</TableCell>
			<TableCell className="p-0.5">
				<Button
					variant="outlineDestructive"
					type="button"
					data-testid="supplier-remove-button"
					onClick={() => {
						remove(sectionIdx);
						// focus on the new supplier if there is one
						if (sectionIdx < fields.length - 1) {
							form.setFocus(`suppliers.${sectionIdx}.name`);
						} else {
							// focus on the previous supplier if there is none
							form.setFocus(`suppliers.${Math.max(sectionIdx - 1, 0)}.name`);
						}
					}}
					onKeyDown={(ev) => {
						const isLastField = sectionIdx === fields.length - 1;
						if (ev.key === 'Tab' && !ev.shiftKey && isLastField) {
							ev.preventDefault();
							copySupplierFromIdx(sectionIdx);
						}
					}}
				>
					<Trash2Icon className="size-4" strokeWidth={1.5} />
				</Button>
			</TableCell>
		</TableRow>
	);
});
