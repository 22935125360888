import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { RouteMenuLink } from './route-menu-link';
import { useTenantFeatureFlags, useTenantIdSafe } from '@/hooks/useTenant';
import { TypographyH5, TypographyP } from '../ui/typography';
import { BuildingIcon, FolderKanbanIcon, GroupIcon, MapPinnedIcon, UserIcon, NotepadTextIcon, TimerResetIcon, Building2Icon, FoldersIcon } from 'lucide-react';
import { PageSidebar } from './page';
import { useRoleGuard } from '@/hooks/useUser';

export function TenantSettingsLayout() {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();
	const { enableLocations, enableGroups, enableProjects, enableDepartments } = useTenantFeatureFlags();

	useRoleGuard('ADMIN');

	return (
		<div className="flex h-full w-full flex-1 flex-grow overflow-hidden">
			<PageSidebar>
				<div className="flex flex-col gap-y-1">
					<TypographyH5 className="line-clamp-2 text-background/75 dark:text-primary/50">{t('settings')}</TypographyH5>

					<TypographyP className="text-background/50 dark:text-primary/50">{t('general')}</TypographyP>
					<RouteMenuLink to={`/tenants/${tenantId}/settings/edit`}>
						<Building2Icon className="h-4 w-4 text-background/80 dark:text-primary/60" />
						{t('company')}
					</RouteMenuLink>

					<RouteMenuLink to={`/tenants/${tenantId}/settings/users`}>
						<UserIcon className="h-4 w-4 text-background/80 dark:text-primary/60" />
						{t('users')}
					</RouteMenuLink>

					{enableGroups && (
						<RouteMenuLink to={`/tenants/${tenantId}/settings/groups`}>
							<GroupIcon className="h-4 w-4 text-background/80 dark:text-primary/60" />
							{t('groups')}
						</RouteMenuLink>
					)}

					{enableProjects && (
						<RouteMenuLink to={`/tenants/${tenantId}/settings/projects`}>
							<FolderKanbanIcon className="h-4 w-4 text-background/80 dark:text-primary/60" />
							{t('projects')}
						</RouteMenuLink>
					)}

					{enableDepartments && (
						<RouteMenuLink to={`/tenants/${tenantId}/settings/departments`}>
							<BuildingIcon className="h-4 w-4 text-background/80 dark:text-primary/60" />
							{t('departments')}
						</RouteMenuLink>
					)}
				</div>

				<div className="flex flex-col gap-y-1">
					<TypographyP className="text-background/50 dark:text-primary/50">{t('inspections')}</TypographyP>
					{enableLocations && (
						<RouteMenuLink to={`/tenants/${tenantId}/settings/locations`}>
							<MapPinnedIcon className="h-4 w-4 text-background/80 dark:text-primary/60" />
							{t('locations')}
						</RouteMenuLink>
					)}
					<RouteMenuLink to={`/tenants/${tenantId}/settings/inspection-forms`}>
						<NotepadTextIcon className="h-4 w-4 text-background/80 dark:text-primary/60" />
						{t('inspection_forms')}
					</RouteMenuLink>
				</div>

				<div className="flex flex-col gap-y-1">
					<TypographyP className="text-background/50 dark:text-primary/50">{t('cases')}</TypographyP>
					<RouteMenuLink to={`/tenants/${tenantId}/settings/case-forms`}>
						<NotepadTextIcon className="h-4 w-4 text-background/80 dark:text-primary/60" />
						{t('case_forms')}
					</RouteMenuLink>
					<RouteMenuLink to={`/tenants/${tenantId}/settings/automatic-cases`}>
						<TimerResetIcon className="h-4 w-4 text-background/80 dark:text-primary/60" />
						{t('automatic_cases')}
					</RouteMenuLink>
				</div>

				<div className="flex flex-col gap-y-1">
					<TypographyP className="text-background/50 dark:text-primary/50">{t('documents')}</TypographyP>
					<RouteMenuLink to={`/tenants/${tenantId}/settings/document-categories`}>
						<FoldersIcon className="h-4 w-4 text-background/80 dark:text-primary/60" />
						{t('document_categories')}
					</RouteMenuLink>
				</div>
			</PageSidebar>

			<div className="flex flex-1 flex-col overflow-auto">
				<Outlet />
			</div>
		</div>
	);
}
