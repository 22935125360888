import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { RouteMenuLink } from './route-menu-link';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { TypographyH5, TypographyP } from '../ui/typography';
import { useSupplier, useSupplierIdSafe } from '@/hooks/useSupplier';
import { PageSidebar } from './page';
import { AlertCircleIcon, CircleUserRoundIcon, FileInputIcon, FileSearch2Icon, FileTextIcon, LineChartIcon, SettingsIcon } from 'lucide-react';

export function SupplierDetailsLayout() {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();
	const supplierId = useSupplierIdSafe();
	const supplier = useSupplier();

	return (
		<div className="flex h-full w-full flex-1 flex-grow overflow-hidden">
			<PageSidebar>
				<div className="flex flex-col gap-y-1">
					<TypographyH5 className="line-clamp-2 text-background/75 dark:text-primary/50">{supplier.data?.name}</TypographyH5>
					{supplier.data?.isArchived && <span className="text-muted-foreground">({t('archived')})</span>}

					<TypographyP className="text-background/50 dark:text-primary/50">{t('general')}</TypographyP>
					<RouteMenuLink to={`/tenants/${tenantId}/suppliers/${supplierId}/overview`}>
						<LineChartIcon className="size-4 text-background/80 dark:text-primary/60" />
						{t('overview')}
					</RouteMenuLink>
					<RouteMenuLink to={`/tenants/${tenantId}/suppliers/${supplierId}/cases`}>
						<FileInputIcon className="size-4 text-background/80 dark:text-primary/60" />
						{t('cases')}
					</RouteMenuLink>
					<RouteMenuLink to={`/tenants/${tenantId}/suppliers/${supplierId}/inspections`}>
						<FileSearch2Icon className="size-4 text-background/80 dark:text-primary/60" />
						{t('inspections')}
					</RouteMenuLink>
					<RouteMenuLink to={`/tenants/${tenantId}/suppliers/${supplierId}/documents`}>
						<FileTextIcon className="size-4 text-background/80 dark:text-primary/60" />
						{t('documents')}
					</RouteMenuLink>
					<RouteMenuLink to={`/tenants/${tenantId}/suppliers/${supplierId}/deviations`}>
						<AlertCircleIcon className="size-4 text-background/80 dark:text-primary/60" />
						{t('deviations')}
					</RouteMenuLink>

					<TypographyP className="text-background/50 dark:text-primary/50">{t('settings')}</TypographyP>
					<RouteMenuLink to={`/tenants/${tenantId}/suppliers/${supplierId}/edit`}>
						<SettingsIcon className="size-4 text-background/80 dark:text-primary/60" />
						{t('supplier')}
					</RouteMenuLink>
					<RouteMenuLink to={`/tenants/${tenantId}/suppliers/${supplierId}/contacts`}>
						<CircleUserRoundIcon className="capitalized size-4 text-background/80 dark:text-primary/60" />
						{t('contacts')}
					</RouteMenuLink>
				</div>
			</PageSidebar>

			<div className="flex flex-1 flex-col overflow-auto">
				<Outlet />
			</div>
		</div>
	);
}
