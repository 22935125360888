import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { FormBuilderSectionContent } from './form-builder-section-content';
import { FormShemaType } from './form-builder-types';
import { LockIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';
import { FormViewer } from '../form-viewers/form-viewer';
import { cn } from '@/lib/utils';
import {
	FormBaseSidebar,
	FormBaseLayout,
	FormBaseMain,
	FormBaseMainContent,
	FormBaseSidebarSectionButton,
	FormBaseMainHeader,
	FormBaseSidebarSectionAddButton,
} from './form-base-layout';
import { FormControl, FormField, FormItem, FormMessage } from '../ui/form';
import { Input } from '../ui/input';

type FormBuilderProps = {
	className?: string;
	locked?: boolean;
};

export function FormBuilder(props: FormBuilderProps) {
	const form = useFormContext<FormShemaType>();
	const [tab, setTab] = useState(() => (props.locked ? 'preview' : 'build'));
	const formContent = form.watch('formContent');
	const { t } = useTranslation();

	// This fixes issue of "No sections" flashing before the form is rendered.
	if (!formContent) return null;

	return (
		<Tabs value={tab} className={cn('flex h-full w-full flex-1 flex-col overflow-y-hidden', props.className)} onValueChange={setTab}>
			<TabsList className="mx-auto mb-2 grid w-[400px] flex-none grid-cols-2">
				<TabsTrigger disabled={props.locked} value="build" asChild={props.locked}>
					{props.locked ? (
						<div className="pointer-events-auto">
							<TooltipProvider>
								<Tooltip>
									<TooltipTrigger>
										<div className="flex w-full items-center">
											<LockIcon className="mr-1 h-4 w-4" />
											<span>{t('build')}</span>
										</div>
									</TooltipTrigger>
									<TooltipContent>
										<p>{t('formbuilder_locked')}</p>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						</div>
					) : (
						t('build')
					)}
				</TabsTrigger>

				<TabsTrigger value="preview">{t('preview')}</TabsTrigger>
			</TabsList>
			<div className="flex flex-1 flex-col overflow-hidden">
				<TabsContent value="build" className={cn('mt-0 flex flex-col overflow-hidden', tab === 'build' && 'flex-1')}>
					<FormBuilderInternal />
				</TabsContent>
				<TabsContent value="preview" className={cn('mt-0 flex flex-col overflow-hidden', tab === 'preview' && 'flex-1')}>
					<FormViewer form={formContent} mode="preview" />
				</TabsContent>
			</div>
		</Tabs>
	);
}

function FormBuilderInternal() {
	const form = useFormContext<FormShemaType>();

	const { t } = useTranslation();
	const [activeSectionIdx, setActiveSectionIdx] = useState(0);

	const { control, formState } = form;
	const errors = formState.errors;
	const {
		fields: sections,
		append,
		remove,
		move,
	} = useFieldArray({
		control,
		name: 'formContent.sections',
	});

	function goToSection(sectionIdx: number) {
		setActiveSectionIdx(sectionIdx);
	}

	/**
	 * Ensure that the active section index is within the bounds of the sections array
	 * This can happen when the formContent is reset from the parent component
	 */
	useEffect(() => {
		if (activeSectionIdx > sections.length - 1) {
			setActiveSectionIdx(sections.length - 1);
		}
	}, [activeSectionIdx, sections.length]);

	/**
	 * Focus the first element with an error when the form is submitted
	 */
	useEffect(() => {
		const sectionWithErrorIdx = errors?.formContent?.sections?.findIndex?.((section) => !!section);
		if (typeof sectionWithErrorIdx === 'number') {
			const element = errors?.formContent?.sections?.at?.(sectionWithErrorIdx)?.elements?.find?.((el) => !!el);
			if (element) {
				goToSection(sectionWithErrorIdx);
				element?.ref?.focus?.();
			}
		}
	}, [errors, form.formState.isValidating]);

	return (
		<FormBaseLayout>
			<FormBaseSidebar>
				{sections.map((section, index) => {
					const sectionName = form.getValues('formContent.sections')[index]?.name; // Workaround to retrieve the latest name for the section
					return (
						<FormBaseSidebarSectionButton
							key={section.id}
							onClick={() => goToSection(index)}
							isActive={index === activeSectionIdx}
							move={(from, to) => {
								move(from, to);
								if (from === activeSectionIdx) {
									setActiveSectionIdx(to);
								}
							}}
							sectionIdx={index}
							onSectionDelete={(idx) => {
								remove(idx);
								if (sections.length === 1) {
									// If this is the last section, clear the sections array
									form.setValue('formContent.sections', []);
								}
								setActiveSectionIdx((c) => Math.max(c - 1, 0));
							}}
						>
							{sectionName}
						</FormBaseSidebarSectionButton>
					);
				})}

				<FormBaseSidebarSectionAddButton
					onClick={() => {
						const newSection: FormShemaType['formContent']['sections'][number] = {
							name: `${t('section')} ${sections.length + 1}`,
							elements: [
								{
									type: 'information',
									text: [
										{
											type: 'paragraph',
											children: [{ text: '' }],
										},
									],
								},
							],
						};

						if (sections.length === 0) {
							// If there are no sections, set the sections array directly
							form.setValue('formContent.sections', [newSection]);
						} else {
							// Otherwise, append to the existing sections
							append(newSection);
						}
						goToSection(sections.length);
					}}
				/>
			</FormBaseSidebar>

			<FormBaseMain>
				<FormBaseMainHeader>
					{activeSectionIdx >= 0 && (
						<FormField
							key={activeSectionIdx}
							control={control}
							name={`formContent.sections.${activeSectionIdx}.name`}
							render={({ field }) => (
								<FormItem>
									<FormControl className="flex flex-col">
										<Input placeholder={t('section_name')} {...field}></Input>
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
					)}
				</FormBaseMainHeader>
				<FormBaseMainContent>
					{sections.length > 0 ? (
						<FormBuilderSectionContent key={activeSectionIdx} sectionIndex={activeSectionIdx} control={control} />
					) : (
						<span className="text-center">{t('no_sections_found')}</span>
					)}
				</FormBaseMainContent>
			</FormBaseMain>
		</FormBaseLayout>
	);
}
