import { useTranslation } from 'react-i18next';
import { RouterOutput, trpc } from '@/lib/providers/trpc';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';
import { useParams, useNavigate } from 'react-router-dom';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { TypographyLabel, TypographyLarge, TypographyMuted, TypographySmall } from '@/components/ui/typography';
import { CheckCircle2Icon, Trash2Icon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { RelativeDate } from '@/components/ui/relative-date';
import { FileActionButton } from '@/components/buttons/file-action-button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Separator } from '@/components/ui/separator';
import { FormViewer } from '@/components/form-viewers/form-viewer';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { ScrollArea } from '@/components/ui/scroll-area';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { tenantInspectionsProcessInputSchema } from '@timp/server/src/schemas/tenant-inspections-process.schema';
import { DeviationSeverityIconLabel } from '@/components/labels/deviation-severity-icon-label';
import { Textarea } from '@/components/ui/textarea';
import { SelectSingleProject, SelectSingleDepartment, SelectSingleLocation, SelectSingleSupplier } from '@/components/selects/select-single';
import { LinkButton } from '@/components/buttons/link-button';
import { useDateFns } from '@/hooks/useDateFns';
import { PartialFormDeviationsList } from '@/components/form-partials/partial-form-deviations-list';
import { DeleteDialog } from '@/components/dropdown-menus/mutation-actions-menu';

export function InspectionsDetailsPage() {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();
	const { inspectionId } = useParams();
	const navigate = useNavigate();

	const inspectionQuery = trpc.tenantInspectionsById.useQuery({
		id: inspectionId!,
		tenantId,
	});

	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const deleteMutation = trpc.tenantInspectionDeleteById.useMutation({
		onSuccess() {
			navigate('../', {
				unstable_flushSync: true,
			});
		},
	});

	const currentTab = inspectionQuery.data?.status === 'PROCESSED' ? 'finished_processing' : 'waiting_for_processing';
	const status = inspectionQuery.data?.status;

	return (
		<Page size="container" isLoading={inspectionQuery.isLoading} error={inspectionQuery.error}>
			<PageToolbar>
				<PageTitle backLink>{inspectionQuery.data?.formTemplateName}</PageTitle>
				<PageActions>
					<Button variant="outlineDestructive" onClick={() => setDeleteDialogOpen(true)}>
						<Trash2Icon className="size-4" />
						{t('delete_inspection')}
					</Button>
				</PageActions>
			</PageToolbar>
			<DeleteDialog
				isLoading={deleteMutation.isLoading}
				name={inspectionQuery.data?.formTemplateName}
				onConfirmDelete={() => {
					deleteMutation.mutate({ id: inspectionId!, tenantId });
				}}
				setOpen={setDeleteDialogOpen}
				open={deleteDialogOpen}
			/>
			<PageContent scroll>
				<Tabs value={currentTab} className="space-y-8">
					<TabsList className="h-auto w-full overflow-x-auto p-2">
						<div className="flex w-full">
							<TabsTrigger
								value="waiting_for_processing"
								className="flex basis-1/2 flex-col items-start justify-center p-3"
								disabled={currentTab !== 'waiting_for_processing'}
							>
								<div className="flex w-full items-center justify-between">
									<span className="font-semibold uppercase">{t('step')} 1</span>
									{status === 'PROCESSED' && <CheckCircle2Icon className="size-4 text-green-600" />}
								</div>
								<TypographyMuted>{t('waiting_for_processing')}</TypographyMuted>
							</TabsTrigger>
							<TabsTrigger
								value="finished_processing"
								className="flex basis-1/2 flex-col items-start justify-center p-3"
								disabled={currentTab !== 'finished_processing'}
							>
								<div className="flex w-full items-center justify-between">
									<span className="font-semibold uppercase">{t('step')} 2</span>
									{status === 'PROCESSED' && <CheckCircle2Icon className="size-4 text-green-600" />}
								</div>
								<TypographyMuted>{t('finished_processing')}</TypographyMuted>
							</TabsTrigger>
						</div>
					</TabsList>
					<TabsContent value="waiting_for_processing">
						<div className="flex flex-col gap-y-8 p-1">
							<ProcessFormSection inspection={inspectionQuery.data!} />
						</div>
					</TabsContent>
					<TabsContent value="finished_processing">
						<div className="flex flex-col gap-y-8 p-1">
							<ProcessedFormSection inspection={inspectionQuery.data!} />
							<DeviationListSection inspection={inspectionQuery.data!} />
						</div>
					</TabsContent>
				</Tabs>
			</PageContent>
		</Page>
	);
}

type Inspection = RouterOutput['tenantInspectionsById'];
interface SectionProps {
	inspection: Inspection;
}
function ProcessFormSection({ inspection }: SectionProps) {
	const tenantId = useTenantIdSafe();
	const [dialogOpen, setDialogOpen] = useState(false);

	const { t } = useTranslation();

	const form = useForm<z.infer<typeof tenantInspectionsProcessInputSchema>>({
		resolver: zodResolver(tenantInspectionsProcessInputSchema),
		values: {
			id: inspection.id,
			deviations: inspection.deviations,
			comment: inspection.comment ?? '',
			supplierId: inspection.supplierId ?? undefined!,
			locationId: inspection.locationId ?? undefined,
			departmentId: inspection.departmentId ?? undefined,
			projectId: inspection.projectId ?? undefined,
		},
	});

	const processMutation = trpc.tenantInspectionsProcess.useMutation({
		onSuccess() {
			setDialogOpen(false);
		},
	});

	const [focusedDeviation, setFocusedDeviation] = useState<string | undefined>(undefined);

	return (
		<div className="flex flex-col gap-y-8 overflow-x-hidden">
			<div className="flex flex-col gap-y-4">
				<div className="flex flex-col items-center gap-x-1 md:flex-row">
					<TypographySmall>
						{t('inspection_created_by_user', {
							name: inspection.createdByInspectorName,
						})}{' '}
					</TypographySmall>
					<TypographyMuted>
						<RelativeDate date={inspection.createdAt} />
					</TypographyMuted>
				</div>
				{inspection.comment && (
					<div>
						<TypographyLabel>{t('comment')}</TypographyLabel>
						<TypographyMuted>{inspection.comment}</TypographyMuted>
					</div>
				)}
				<FileActionButton
					onClick={() => {
						setDialogOpen(true);
					}}
					type="button"
					filename={inspection.formTemplateName}
				>
					{t('process_inspection')}
				</FileActionButton>
			</div>

			<Dialog
				open={dialogOpen}
				onOpenChange={(open) => {
					setDialogOpen(open);
				}}
			>
				<DialogContent size="full">
					<DialogHeader>
						<DialogTitle className="mb-2">{inspection.formTemplateName}</DialogTitle>
						<Separator />
					</DialogHeader>

					<div className="flex flex-1 gap-x-4 overflow-hidden">
						<FormViewer form={inspection.formContent} mode="view" />
						<Separator orientation="vertical" />
						<Form {...form}>
							<form className="flex w-1/4 flex-col gap-y-4 lg:w-1/5" onSubmit={form.handleSubmit((values) => processMutation.mutate({ ...values, tenantId }))}>
								<TypographyLarge>{t('process')}</TypographyLarge>
								<ScrollArea>
									<div className="flex flex-col gap-y-8">
										<div className="flex flex-col gap-y-2">
											<FormField
												control={form.control}
												name="supplierId"
												render={({ field }) => (
													<FormItem>
														<FormLabel required> {t('supplier')}</FormLabel>
														<SelectSingleSupplier value={field.value} onChange={field.onChange} />
														<FormMessage />
													</FormItem>
												)}
											/>
											<FormField
												control={form.control}
												name="locationId"
												render={({ field }) => (
													<FormItem>
														<FormLabel required={false}> {t('location')}</FormLabel>
														<SelectSingleLocation value={field.value} onChange={field.onChange} />
														<FormMessage />
													</FormItem>
												)}
											/>
											<FormField
												control={form.control}
												name="departmentId"
												render={({ field }) => (
													<FormItem>
														<FormLabel required={false}> {t('department')}</FormLabel>
														<SelectSingleDepartment value={field.value} onChange={field.onChange} />
														<FormMessage />
													</FormItem>
												)}
											/>
											<FormField
												control={form.control}
												name="projectId"
												render={({ field }) => (
													<FormItem>
														<FormLabel required={false}> {t('project')}</FormLabel>
														<SelectSingleProject value={field.value} onChange={field.onChange} />
														<FormMessage />
													</FormItem>
												)}
											/>

											<PartialFormDeviationsList form={form} setFocusedDeviation={setFocusedDeviation} focusedDeviation={focusedDeviation} />
										</div>
										<FormField
											control={form.control}
											name="comment"
											render={({ field }) => (
												<FormItem>
													<FormLabel required>{t('comment')}</FormLabel>
													<FormControl>
														<Textarea {...field} rows={5} />
													</FormControl>
													<FormMessage />
												</FormItem>
											)}
										/>
									</div>
								</ScrollArea>

								<div className="flex gap-x-2">
									<Button type="button" onClick={() => setDialogOpen(false)} disabled={processMutation.isLoading} variant="outline" className="w-1/2">
										{t('cancel')}
									</Button>
									<Button isLoading={processMutation.isLoading} type="submit" className="w-1/2">
										{t('process')}
									</Button>
								</div>
							</form>
						</Form>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}

function DeviationListSection({ inspection }: SectionProps) {
	const { t } = useTranslation();
	return (
		<div className="flex flex-col gap-y-4 overflow-x-auto">
			<div className="flex gap-x-2">
				<TypographyLabel>{t('deviations')}</TypographyLabel>
			</div>
			{inspection.deviations.length > 0 ? (
				<ul className="flex flex-col gap-y-2">
					{inspection.deviations.map((deviation) => (
						<li key={deviation.id} className="flex flex-col gap-y-2 rounded-md bg-muted p-3">
							<DeviationSeverityIconLabel severity={deviation.severity} />
							<TypographyMuted>{deviation.description}</TypographyMuted>
						</li>
					))}
				</ul>
			) : (
				<TypographyMuted>{t('no_deviations')}</TypographyMuted>
			)}
		</div>
	);
}

function ProcessedFormSection({ inspection }: SectionProps) {
	const tenantId = useTenantIdSafe();
	const { t } = useTranslation();
	const [dialogOpen, setDialogOpen] = useState(false);
	const { format } = useDateFns();

	return (
		<div className="flex flex-col gap-y-8 overflow-y-auto">
			<FileActionButton
				onClick={() => {
					setDialogOpen(true);
				}}
				type="button"
				filename={inspection.formTemplateName}
			>
				{t('open_form')}
			</FileActionButton>
			<Dialog
				open={dialogOpen}
				onOpenChange={(open) => {
					setDialogOpen(open);
				}}
			>
				<DialogContent size="full">
					<DialogHeader>
						<DialogTitle className="mb-2">{inspection.formTemplateName}</DialogTitle>
						<Separator />
					</DialogHeader>
					<div className="flex flex-1 overflow-hidden">
						<FormViewer form={inspection.formContent} mode="view" />
					</div>
				</DialogContent>
			</Dialog>
			<div className="flex gap-x-4">
				<div className="flex basis-1/2 flex-col gap-y-2 rounded-md bg-muted p-3">
					<TypographyLabel>{t('supplier')}</TypographyLabel>

					<LinkButton variant="link" className="inline p-0 text-blue-600" target="_blank" to={`/tenants/${tenantId}/suppliers/${inspection.supplierId}`}>
						{inspection.supplierName}
					</LinkButton>
				</div>

				<div className="flex basis-1/2 flex-col gap-y-2 rounded-md bg-muted p-3">
					<TypographyLabel>{t('location')}</TypographyLabel>
					<TypographyMuted>{inspection.locationName ?? '-'}</TypographyMuted>
				</div>
			</div>
			<div className="flex gap-x-4">
				<div className="flex basis-1/2 flex-col gap-y-2 rounded-md bg-muted p-3">
					<TypographyLabel>{t('project')}</TypographyLabel>
					<TypographyMuted>{inspection.projectName ?? '-'}</TypographyMuted>
				</div>

				<div className="flex basis-1/2 flex-col gap-y-2 rounded-md bg-muted p-3">
					<TypographyLabel>{t('department')}</TypographyLabel>
					<TypographyMuted>{inspection.departmentName ?? '-'}</TypographyMuted>
				</div>
			</div>

			<div className="flex gap-x-4">
				<div className="flex basis-1/2 flex-col gap-y-2 rounded-md bg-muted p-3">
					<TypographyLabel>{t('INSPECTOR')}</TypographyLabel>
					<TypographyMuted>
						{inspection.createdByInspectorName} at {format(new Date(inspection.createdAt), 'Pp')}
					</TypographyMuted>
				</div>
				<div className="flex basis-1/2 flex-col gap-y-2 rounded-md bg-muted p-3">
					<TypographyLabel>{t('processed_by')}</TypographyLabel>
					<TypographyMuted>
						{inspection.processedByName} {inspection.processedAt && <span>at {format(new Date(inspection.processedAt), 'Pp')}</span>}
					</TypographyMuted>
				</div>
			</div>
			<div className="flex gap-x-4">
				<div className="flex min-h-24 basis-full flex-col gap-y-2 rounded-md bg-muted p-3">
					<TypographyLabel>{t('comment')}</TypographyLabel>
					<TypographyMuted>{inspection.comment}</TypographyMuted>
				</div>
			</div>
		</div>
	);
}
