import { DataTable } from '@/components/tables/data-table';
import { trpc } from '@/lib/providers/trpc';
import { useTranslation } from 'react-i18next';
import { RelativeDate } from '@/components/ui/relative-date';
import { LinkButton } from '@/components/buttons/link-button';
import { DataTableLink } from '@/components/ui/data-table-link';
import { MutationActionsMenu, MutationCloneAction, MutationDeleteAction, MutationEditAction } from '@/components/dropdown-menus/mutation-actions-menu';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@/components/icons/spinner';
import { BookCheckIcon, BookDashedIcon, ChevronDownIcon, FileInputIcon } from 'lucide-react';
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';
import { Button } from '@/components/ui/button';
import { Menubar, MenubarContent, MenubarItem, MenubarMenu, MenubarSub, MenubarSubContent, MenubarSubTrigger, MenubarTrigger } from '@/components/ui/menubar';

export function TenantSettingsCaseFormsPage() {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();
	const navigate = useNavigate();

	const {
		data = [],
		isLoading,
		error,
	} = trpc.tenantFormTemplatesList.useQuery({
		types: ['CASE'],
		includeUnpublished: true,
		tenantId,
	});

	const { mutate: deleteMutation } = trpc.tenantFormTemplatesDeleteById.useMutation();

	const {
		mutate: copyMutation,
		isLoading: copyIsLoading,
		variables: copyVariables,
	} = trpc.tenantFormTemplatesCopy.useMutation({
		onSuccess: (data) => {
			navigate(`./${data.id}/edit`);
		},
	});

	const sysadminTemplates = trpc.tenantSysadminFormTemplatesList.useQuery({
		tenantId,
		types: ['CASE'],
	});

	return (
		<Page isLoading={isLoading} error={error}>
			<PageToolbar>
				<PageTitle>{t('form_title_case')}</PageTitle>
				<PageActions>
					<Menubar className="border-none p-0 shadow-none">
						<MenubarMenu>
							<MenubarTrigger asChild>
								<Button className="cursor-pointer">
									{t('form_create')}
									<ChevronDownIcon className="size-4" />
								</Button>
							</MenubarTrigger>
							<MenubarContent>
								<MenubarItem asChild>
									<LinkButton to={'./create'} variant="ghost" className="cursor-pointer justify-start">
										<FileInputIcon className="size-4" />
										{t('start_from_blank')}
									</LinkButton>
								</MenubarItem>

								<MenubarSub>
									<MenubarSubTrigger className="cursor-pointer p-0">
										<Button type="button" variant="ghost" className="cursor-pointer justify-start px-2">
											<FileInputIcon className="size-4" />
											{t('from_template')}
										</Button>
									</MenubarSubTrigger>

									{sysadminTemplates.data?.length && (
										<MenubarSubContent>
											{sysadminTemplates.data?.map((templ) => (
												<MenubarItem key={templ.id} className="cursor-pointer" asChild>
													<LinkButton className="justify-start" to={`./create?copyFromTemplateId=${templ.id}`} variant="ghost">
														{templ.name}
													</LinkButton>
												</MenubarItem>
											))}
										</MenubarSubContent>
									)}
								</MenubarSub>
							</MenubarContent>
						</MenubarMenu>
					</Menubar>
				</PageActions>
			</PageToolbar>

			<PageContent>
				<DataTable
					columnsStorageKey="tenant-case-forms-page"
					isLoading={isLoading}
					error={error}
					data={data}
					columns={[
						{
							id: 'name',
							title: t(`name`),
							render(row) {
								return <DataTableLink to={`./${row.id}/edit`}>{row.name}</DataTableLink>;
							},
						},
						{
							id: 'published',
							title: t(`published`),
							render(row) {
								if (row.published) {
									return (
										<div className="flex items-center gap-x-1">
											<BookCheckIcon className="size-4" />
											{t('published')}
										</div>
									);
								} else {
									return (
										<div className="flex items-center gap-x-1">
											<BookDashedIcon className="size-4" />
											{t('draft')}
										</div>
									);
								}
							},
						},
						{ id: 'updatedAt', title: t('updated_at'), render: (row) => <RelativeDate date={row.updatedAt} /> },
						{ id: 'createdAt', title: t('created_at'), render: (row) => <RelativeDate date={row.createdAt} /> },
						{
							id: 'id',
							title: t('actions'),
							actions: true,
							enableSorting: false,
							size: 40,
							render(row) {
								if (copyIsLoading && copyVariables?.copiedFromId === row.id) {
									return <Spinner />;
								}
								return (
									<MutationActionsMenu data-testid={row.name}>
										<MutationCloneAction
											onClick={() => {
												copyMutation({ copiedFromId: row.id, tenantId });
											}}
										/>
										<MutationEditAction to={`./${row.id}/edit`} />
										<MutationDeleteAction name={row.name} onConfirmDelete={() => deleteMutation({ id: row.id, tenantId })} />
									</MutationActionsMenu>
								);
							},
						},
					]}
					searchColumn="name"
					defaultSortColumn="name"
				/>
			</PageContent>
		</Page>
	);
}
