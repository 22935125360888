import { DataTable } from '@/components/tables/data-table';
import { trpc } from '@/lib/providers/trpc';
import { useTranslation } from 'react-i18next';
import { RelativeDate } from '@/components/ui/relative-date';
import { LinkButton } from '@/components/buttons/link-button';
import { DataTableLink } from '@/components/ui/data-table-link';
import { MutationActionsMenu, MutationCloneAction, MutationDeleteAction, MutationEditAction } from '@/components/dropdown-menus/mutation-actions-menu';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { BookCheckIcon, BookDashedIcon, ChevronDownIcon, DownloadIcon, FileInputIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@/components/icons/spinner';
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';
import { QRCodeSVG } from 'qrcode.react';
import { useState } from 'react';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { TypographyLabel, TypographyMuted } from '@/components/ui/typography';
import { Button } from '@/components/ui/button';
import { renderToString } from 'react-dom/server';
import { Menubar, MenubarContent, MenubarItem, MenubarMenu, MenubarSub, MenubarSubContent, MenubarSubTrigger, MenubarTrigger } from '@/components/ui/menubar';

export function TenantSettingsInspectionFormsPage() {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();
	const navigate = useNavigate();

	const [clickedFormId, setClickedFormId] = useState<string | null>(null);

	const formIdToUrl = (id: string) => `${window.location.origin}/inspector/tenants/${tenantId}/forms/${id}`;

	const {
		data = [],
		isLoading,
		error,
	} = trpc.tenantFormTemplatesList.useQuery({
		types: ['INSPECTION'],
		includeUnpublished: true,
		tenantId,
	});

	const { mutate: deleteMutation } = trpc.tenantFormTemplatesDeleteById.useMutation();

	const {
		mutate: copyMutation,
		isLoading: copyIsLoading,
		variables: copyVariables,
	} = trpc.tenantFormTemplatesCopy.useMutation({
		onSuccess: (data) => {
			navigate(`./${data.id}/edit`);
		},
	});

	function downloadQRCode() {
		const svgData = renderToString(<QRCodeSVG xmlns="http://www.w3.org/2000/svg" value={formIdToUrl(clickedFormId!)} size={512} />);
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d')!;
		const img = new Image();

		img.onload = () => {
			canvas.width = img.width;
			canvas.height = img.height;
			ctx.drawImage(img, 0, 0);
			const pngFile = canvas.toDataURL('image/png');
			const downloadLink = document.createElement('a');
			downloadLink.download = data.find((f) => f.id === clickedFormId)?.name ?? 'QR';
			downloadLink.href = `${pngFile}`;
			downloadLink.click();
		};
		img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
	}

	const sysadminTemplates = trpc.tenantSysadminFormTemplatesList.useQuery({
		tenantId,
		types: ['INSPECTION'],
	});

	return (
		<Page isLoading={isLoading}>
			<PageToolbar>
				<PageTitle>{t('form_title_inspection')}</PageTitle>
				<PageActions>
					<Menubar className="border-none p-0 shadow-none">
						<MenubarMenu>
							<MenubarTrigger asChild>
								<Button className="cursor-pointer">
									{t('form_create')}
									<ChevronDownIcon className="size-4" />
								</Button>
							</MenubarTrigger>
							<MenubarContent>
								<MenubarItem asChild>
									<LinkButton to={'./create'} variant="ghost" className="cursor-pointer justify-start">
										<FileInputIcon className="size-4" />
										{t('start_from_blank')}
									</LinkButton>
								</MenubarItem>

								<MenubarSub>
									<MenubarSubTrigger className="cursor-pointer p-0">
										<Button type="button" variant="ghost" className="cursor-pointer justify-start px-2">
											<FileInputIcon className="size-4" />
											{t('from_template')}
										</Button>
									</MenubarSubTrigger>

									{sysadminTemplates.data?.length && (
										<MenubarSubContent>
											{sysadminTemplates.data?.map((templ) => (
												<MenubarItem key={templ.id} className="cursor-pointer" asChild>
													<LinkButton className="justify-start" to={`./create?copyFromTemplateId=${templ.id}`} variant="ghost">
														{templ.name}
													</LinkButton>
												</MenubarItem>
											))}
										</MenubarSubContent>
									)}
								</MenubarSub>
							</MenubarContent>
						</MenubarMenu>
					</Menubar>
				</PageActions>
			</PageToolbar>

			<PageContent>
				<DataTable
					columnsStorageKey="tenant-inspection-forms-page"
					isLoading={isLoading}
					error={error}
					data={data}
					columns={[
						{
							id: 'name',
							title: t(`name`),
							render(row) {
								return <DataTableLink to={`./${row.id}/edit`}>{row.name}</DataTableLink>;
							},
						},
						{
							id: 'published',
							title: t(`published`),
							render(row) {
								if (row.published) {
									return (
										<div className="flex items-center gap-x-1">
											<BookCheckIcon className="size-4" />
											{t('published')}
										</div>
									);
								} else {
									return (
										<div className="flex items-center gap-x-1">
											<BookDashedIcon className="size-4" />
											{t('draft')}
										</div>
									);
								}
							},
						},
						{ id: 'updatedAt', title: t('updated_at'), render: (row) => <RelativeDate date={row.updatedAt} /> },
						{ id: 'createdAt', title: t('created_at'), render: (row) => <RelativeDate date={row.createdAt} /> },
						{
							id: 'id',
							title: t('qr_code'),
							enableSorting: false,
							render(row) {
								if (row.published) {
									return (
										<QRCodeSVG
											data-testid={`${row.name} qr-code`}
											className="cursor-pointer"
											value={formIdToUrl(row.id)}
											size={64}
											onClick={() => {
												setClickedFormId(row.id);
											}}
										/>
									);
								} else {
									return t('not_published');
								}
							},
						},
						{
							id: 'id',
							title: t('actions'),
							actions: true,
							enableSorting: false,
							size: 40,
							render(row) {
								if (copyIsLoading && copyVariables?.copiedFromId === row.id) {
									return <Spinner />;
								}
								return (
									<MutationActionsMenu data-testid={row.name}>
										<MutationCloneAction
											onClick={() => {
												copyMutation({ copiedFromId: row.id, tenantId });
											}}
										/>
										<MutationEditAction to={`./${row.id}/edit`} />
										<MutationDeleteAction name={row.name} onConfirmDelete={() => deleteMutation({ id: row.id, tenantId })} />
									</MutationActionsMenu>
								);
							},
						},
					]}
					searchColumn="name"
					defaultSortColumn="createdAt"
					defaultSortOrder="desc"
				/>
			</PageContent>

			<Dialog open={!!clickedFormId} onOpenChange={() => setClickedFormId(null)}>
				<DialogContent size="dynamic">
					<div className="flex h-full flex-col justify-center gap-y-8 p-4 md:p-8">
						<div className="flex flex-col items-center gap-y-4 md:gap-y-6">
							<QRCodeSVG value={formIdToUrl(clickedFormId!)} className="h-auto w-full max-w-96" />
							<Button
								variant="ghost"
								onClick={() => {
									downloadQRCode();
								}}
							>
								<DownloadIcon />
								<TypographyLabel className="capitalize">{t('download')}</TypographyLabel>
							</Button>
						</div>
						<a href={formIdToUrl(clickedFormId!)} target="_blank" className="text-center hover:underline" data-testid="external-qr-link">
							<TypographyMuted>{formIdToUrl(clickedFormId!)}</TypographyMuted>
						</a>
					</div>
				</DialogContent>
			</Dialog>
		</Page>
	);
}
